import { createStandaloneToast } from '@chakra-ui/react';
import { getMessaging, getToken } from 'firebase/messaging';
import firebase from 'firebase/app';
import 'firebase/compat/messaging';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { MiddlewareAPI } from '@reduxjs/toolkit';
import Notification from '../../Components/Common/Notification/Notification';
import { userApi } from '../../services/user';
import { onNotificationMessage } from '../../redux/userSlice';

const notify = createStandaloneToast();
class NotificationService {
  private messaging: any;
  public readonly unreadedCount: number;
  private store: MiddlewareAPI | null;

  constructor() {
    this.unreadedCount = 0;
    this.store = null;
    this.registerMessaging();
  }

  private registerMessaging() {
    const firebaseConfig: FirebaseOptions = {
      apiKey: 'AIzaSyBt1iKQk-3LaBWoKqeIWa5zwx860rGnhxQ',
      authDomain: 'balkan-d42da.firebaseapp.com',
      projectId: 'balkan-d42da',
      storageBucket: 'balkan-d42da.appspot.com',
      messagingSenderId: '874138305029',
      appId: '1:874138305029:web:985dd71e96029dfd3cd26c',
      measurementId: 'G-N3HVGC80ZE',
    };
    const app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(app);
  }

  private onNotification = (message: any) => {
    this.store?.dispatch(onNotificationMessage());
    notify({
      position: 'bottom-left',
      duration: 6000,
      isClosable: true,
      render: ({ onClose }) => (
        <Notification
          title='הוֹדָעָה'
          text={message.notification.body}
          onClose={onClose}
        />
      ),
    });
  };

  private async sentToken(token: string) {
    if (!this.store) return;
    fetch(`https://balkan-api.dev.bsx-group.com/api/profile/change-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.store.getState().user.info.api_token}`,
      },
      body: JSON.stringify({ device_token: token }),
    });
  }

  public async connect(store: MiddlewareAPI) {
    this.store = store;
    if (!('Notification' in window)) {
      console.log('NOTIFICATIONS OFF');
    } else {
      console.log('NOTIFICATIONS ON');
      try {
        const token = await getToken(this.messaging);
        await this.sentToken(token);
        this.messaging.onMessageHandler = this.onNotification;
      } catch (e: any) {
        console.log('Error in connection to firebase messaging', e.message);
      }
    }
  }
}
const notificationService = new NotificationService();
export default notificationService;
