export const Input = {
  variants: {
    first: {
      w: 'full',
      bg: 'brand.grey',
      borderColor: 'white',
      border: '1px',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '20px',
    },
  },
};
