import {
  Box,
  BoxProps,
  Center,
  CenterProps,
  Flex,
  FlexProps,
  Image,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Portret from '../../../Images/PNG/Portret.png';
import PortretBG from '../../../Images/PNG/PortretBG.png';
import textPortret from '../../../Images/SVG/TextPortret.svg';
import { userApi } from '../../../services/user';
import LoginFirstForm from './LoginFirstForm/LoginFirstForm';
import LoginSecondForm from './LoginSecondForm/LoginSecondForm';
import LoginThirdForm from './LoginThirdForm/LoginThirdForm';
import { useAppSelector } from '../../../hooks/redux';
import { useNavigate } from 'react-router';
import { defaultRoutesByRole } from '../../../routes/routes';

interface Props {
  email?: boolean;
  password?: boolean;
}

const Login = ({ email, password }: Props) => {
  const userRole = useAppSelector((state) => state.user.info.role);
  const navigate = useNavigate();

  useEffect(() => {
    if (userRole !== 'none') {
      navigate(defaultRoutesByRole[userRole]);
    }
  }, [userRole]);

  return (
    <Box
      {...pageWrap}
      bg='brand.black'
      bgImg={PortretBG}
      bgRepeat='no-repeat'
      bgSize='cover'
    >
      <Flex {...flexWrap} bg='transparent' id='login'>
        <Center {...topSection}>
          <Image src={Portret} alt='Portret' ml='80px' mt='-90px' />
          <Image src={textPortret} alt='text' />
        </Center>
        {!email && !password && <LoginFirstForm />}
        {email && <LoginSecondForm />}
        {password && <LoginThirdForm />}
      </Flex>
    </Box>
  );
};

const pageWrap: BoxProps = {
  w: '100vw',
  h: '100vh',
  overflow: 'auto',
  sx: {
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '5px',
      borderRadius: '15px',
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `grey`,
      borderRadius: '10px',
    },
  },
};

const flexWrap: FlexProps = {
  h: '100vh',
  w: 'full',
  bgRepeat: 'no-repeat',
  flexDir: 'column',
};

const topSection: CenterProps = {
  w: 'full',
  h: '210px',
  bgImage: 'linear-gradient(90deg, #6e1105 50%, #891308 50%)',
  bgSize: '2rem 2rem',
  mt: '135px',
  flexDir: 'column',
};

export default Login;
