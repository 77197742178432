import { IPaths } from "../types/types";

export const paths: IPaths = {
  dashboard: "/dashboard",
  orders: "/orders",
  orderDone: "/order/done",
  ordersHistory: "/orders/history",
  ordersShoppingList: "/orders/shopping-list",
  ordersCart: "/orders/cart",
  ordersNew: "/orders/view",
  ordersNewNeedToPay: "/orders/need-to-pay",
  ordersInProgress: "/orders/in-progress",
  ordersAddNewPrivious: "/orders/add-new-privious",
  ordersSentView: "/orders/sent-view",
  ordersCenceledView: "/orders/cenceled-view",
  ordersNewView: "/orders/view",
  client: "/client",
  inClient: "/client/in-client",
  inClientReport: "/client/in-client/report",
  inClientInReport: "/client/in-client/in-report",
  inClientHome: "/client/in-client/home",
  inClientReceipt: "/client/in-client/receipt",
  inClientFlag: "/client/in-client/flag",
  inClientPriceList: "/client/in-client/price-list",
  inClientUser: "/client/in-client/user",
  inClientArchive: "/client/in-client/archive",
  inClientSettings: "/client/in-client/settings",
  inClientPaymentStatistics: "/client/in-client/payment-statistics",
  newClient: "/client/new-client",
  reports: "/reports",
  tasks: "/tasks",
  tasksMore: "/tasks/more",
  sendNotification: "/send-notification",
  reportsClientActivity: "/reports/client-activity",
  reportsClientPricing: "/reports/client-pricing",
  reportsAgentGoals: "/reports/agent-goals",
  feedbacks: "/feedbacks",
  questionnaires: "/questions",
  deals: "/deals",
  addDeal: "/deals/add-deal",
  notifications: "/notifications",
  customers: "/customers",
  customersInClient: "/customers/client/in-client",
  customersInClientFlag: "/customers/client/in-client/flag",
  customersInClientUser: "/customers/client/in-client/user",
  customersInClientSettings: "/customers/client/in-client/settings",
  agents: "/agents",
  returns: "/returns",
  returnsView: "/returns/view",
  prioritizeShipments: "/prioritize-shipments",
  prioritizeShipmentsDeliveryPriority:
    "/prioritize-shipments/delivery-priority",
  driverTasks: "/driver-tasks",
  information: "/information/driver",
  informationCargoTruck: "/information/cargo-truck",
  informationRoute: "/information/route",
  concentrationReports: "/concentration-reports",
  concentrationReportsView: "/concentration-reports/view",
  divisionLines: "/division-lines",
  inFeedback: "/feedbacks/in-feedback",
  newQuestion: "/questions/new-question",
  questionReport: "/questions/question-report",
  inAgentView: "/agents/in-agent-view",
  newAgent: "/agents/new-agent",
  inDriverTask: "/tasks/in-driver-task",
  inDriverTaskDone: "/orders/in-driver-task-done",
  products: "/products",
  reportsDailySales: "/reports/daily-sales",
  reportsClientSales: "/reports/client-sales",
  reportsGiuls: "/reports/giuls",
};
