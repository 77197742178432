import {
  AgentOrderResponse,
  IAgent,
  IAgentGetResponse,
  IAreasGraphAgent,
  ICreateAgent,
  ICreateNewTaskAgent,
  ILineGraphAgent,
  IMonthsGraphAgent,
  IShowAgentTaskResponse,
  SubAgent,
} from './../models/IAgent/IAgent';
import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const agentsApi = createApi({
  reducerPath: 'agentsApi',
  tagTypes: ['Agent'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getAgents: build.query<
      IAgentGetResponse,
      { query: string; page: number; number_area: string }
    >({
      transformResponse: (response: { data: IAgentGetResponse }) =>
        response.data,
      query: ({ query = '', page = 1, number_area }) => ({
        url: 'api/agents',
        method: 'GET',
        params: {
          search: query,
          page,
          number_area,
        },
      }),
      providesTags: (result) => ['Agent'],
    }),
    taskListAgents: build.query<
      IAgentGetResponse,
      { search: string; page: number }
    >({
      transformResponse: (response: { data: IAgentGetResponse }) =>
        response.data,
      query: ({ search, page }) => ({
        url: '/api/tasks',
        method: 'GET',
        params: {
          search,
          page,
        },
      }),
      providesTags: (result) => ['Agent'],
    }),
    showAgentTask: build.query<
      IShowAgentTaskResponse,
      {
        search: string;
        page: number;
        id: number;
        is_done: number | string;
        to_date: string;
        from_date: string;
      }
    >({
      transformResponse: (response: { data: IShowAgentTaskResponse }) =>
        response.data,
      query: ({ search, page, id, is_done, from_date, to_date }) => ({
        url: `/api/tasks/${id}`,
        method: 'GET',
        params: {
          search,
          page,
          is_done,
          from_date,
          to_date,
        },
      }),
      providesTags: (result) => ['Agent'],
    }),
    createNewTaskAgent: build.mutation<
      ICreateNewTaskAgent,
      { message: string; agent_id: number }
    >({
      // transformResponse: (response: { data: ICreateNewTaskAgent }) =>
      //   response.data,
      query: ({ message, agent_id }) => ({
        url: `/api/tasks/${agent_id}`,
        method: 'POST',
        body: { message },
      }),
    }),
    addNewMessageToTask: build.mutation<
      ICreateNewTaskAgent,
      { message: string; agent_id: number; task_id: number }
    >({
      // transformResponse: (response: { data: ICreateNewTaskAgent }) =>
      //   response.data,
      query: ({ message, agent_id, task_id }) => ({
        url: `/api/tasks/${agent_id}/${task_id}`,
        method: 'POST',
        body: { message },
      }),
    }),
    doneTask: build.mutation<
      ICreateNewTaskAgent,
      { agent_id: number; task_id: number }
    >({
      // transformResponse: (response: { data: ICreateNewTaskAgent }) =>
      //   response.data,
      query: ({ agent_id, task_id }) => ({
        url: `/api/tasks/${agent_id}/${task_id}/done`,
        method: 'POST',
      }),
    }),
    showTask: build.query<
      ICreateNewTaskAgent,
      { agent_id: number; task_id: number }
    >({
      // transformResponse: (response: { data: ICreateNewTaskAgent }) =>
      //   response.data,
      query: ({ agent_id, task_id }) => ({
        url: `/api/tasks/${agent_id}/${task_id}`,
        method: 'GET',
      }),
    }),
    getSubAgents: build.query<SubAgent[], string>({
      transformResponse: (response: { data: SubAgent[] }) => response.data,
      query: (agent_id) => ({
        url: '/api/agents/short-list',
        method: 'GET',
        // params: {
        //   agent_id,
        // },
      }),
      providesTags: (result) => ['Agent'],
    }),
    createAgent: build.mutation<IAgent, ICreateAgent>({
      transformResponse: (response: { data: IAgent }) => response.data,
      query: (newAgent) => ({
        url: 'api/agents',
        method: 'POST',
        body: newAgent,
      }),
      invalidatesTags: ['Agent'],
    }),
    updateAgent: build.mutation<IAgent, { id: string; data: any }>({
      transformResponse: (response: { data: IAgent }) => response.data,
      query: ({ id, data }) => ({
        url: `api/agents/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Agent'],
    }),
    showAgents: build.query<IAgent, number>({
      transformResponse: (response: { data: IAgent }) => response.data,
      query: (id) => ({
        url: `/api/agents/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => ['Agent'],
    }),
    getAgentOrders: build.query<
      AgentOrderResponse,
      {
        search: string;
        page: number;
        id: string;
        to_date: string;
        from_date: string;
        status_id: string;
        business_id: string;
      }
    >({
      transformResponse: (response: { data: AgentOrderResponse }) =>
        response.data,
      query: ({
        search,
        page,
        id,
        to_date,
        from_date,
        status_id,
        business_id,
      }) => ({
        url: `/api/agents/${id}/orders`,
        method: 'GET',
        params: {
          search,
          page,
          from_date,
          status_id,
          business_id,
          to_date,
        },
      }),
    }),
    areasGraphAgent: build.query<
      IAreasGraphAgent[],
      { id: string; from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: IAreasGraphAgent[] }) =>
        response.data,
      query: ({ id, from_date, to_date }) => ({
        url: `/api/agents/${id}/areas-graph`,
        method: 'GET',
        params: {
          from_date,
          to_date,
        },
      }),
      providesTags: (result) => ['Agent'],
    }),
    monthsGraphAgent: build.query<IMonthsGraphAgent[], string>({
      transformResponse: (response: { data: IMonthsGraphAgent[] }) =>
        response.data,
      query: (id) => ({
        url: `/api/agents/${id}/months-graph`,
        method: 'GET',
      }),
      providesTags: (result) => ['Agent'],
    }),
    lineGraphAgent: build.query<ILineGraphAgent, string>({
      transformResponse: (response: { data: ILineGraphAgent }) => response.data,
      query: (id) => ({
        url: `/api/agents/${id}/line-graph`,
        method: 'GET',
      }),
      providesTags: (result) => ['Agent'],
    }),
  }),
});
