import {
  Center,
  FormControl,
  FormLabel,
  FormLabelProps,
  Image,
  InputGroup,
  InputLeftElement,
  InputProps,
  Text,
  Wrap,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import IButton from '../../../Common/Button/Button';
import { Input } from '../../../Common/Input/Input';
import eye from '../../../../Images/SVG/eye.svg';
import { userApi } from '../../../../services/user';
import { useLocation, useNavigate, useParams } from 'react-router';

const LoginThirdForm = () => {
  const [firstPassword, setFirstPassword] = useState('');
  const [firstShowPass, setFirtShowPass] = useState(false);
  const [secondPassword, setSecondPassword] = useState('');
  const [secondShowPass, setSecondShowPass] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');

  const [resetPassword, { error, isLoading }] =
    userApi.useResetPasswordMutation();

  const handleClick = () => {
    resetPassword({
      email: email || '',
      password: firstPassword,
      password_confirmation: secondPassword,
      token: token || '',
    })
      .unwrap()
      .then(() => navigate('/login'));
  };

  return (
    <Center mt="45px">
      <Center w="380px" flexDir="column">
        <Text fontWeight="bold" fontSize="24px" color="brand.yellow">
          איפוס סיסמה
        </Text>
        <Text fontSize="18px" color="brand.yellow" textAlign="center" mt="15px">
          הזינו סיסמה חדשה
        </Text>
        <FormControl maxW="345px" w="full" mt="15px">
          <FormLabel {...labelStyle}>סיסמה</FormLabel>
          <InputGroup>
            <Input
              {...inputStyle}
              className="login"
              onChange={(e) => setFirstPassword(e.target.value)}
              value={firstPassword}
              id="password"
              type={firstShowPass ? 'text' : 'password'}
              border={error ? '1px solid red' : '1px solid brand.white'}
            />
            <InputLeftElement
              h="full"
              w="50px"
              onClick={() => setFirtShowPass(!firstShowPass)}
            >
              <Center
                pos="relative"
                _after={
                  firstShowPass
                    ? {
                        content: '""',
                        pos: 'absolute',
                        bg: 'brand.white',
                        w: 'full',
                        h: '1px',
                        transform: 'rotate(-40deg)',
                      }
                    : {}
                }
              >
                <Image src={eye} alt="" />
              </Center>
            </InputLeftElement>
          </InputGroup>
        </FormControl>
        <FormControl maxW="345px" mt="15px">
          <FormLabel {...labelStyle}>סיסמה שוב</FormLabel>
          <InputGroup>
            <Input
              {...inputStyle}
              className="login"
              onChange={(e) => setSecondPassword(e.target.value)}
              value={secondPassword}
              id="password"
              type={secondShowPass ? 'text' : 'password'}
              border={error ? '1px solid red' : '1px solid brand.white'}
            />
            <InputLeftElement
              h="full"
              w="50px"
              onClick={() => setSecondShowPass(!secondShowPass)}
            >
              <Center
                pos="relative"
                _after={
                  secondShowPass
                    ? {
                        content: '""',
                        pos: 'absolute',
                        bg: 'brand.white',
                        w: 'full',
                        h: '1px',
                        transform: 'rotate(-40deg)',
                      }
                    : {}
                }
              >
                <Image src={eye} alt="" />
              </Center>
            </InputLeftElement>
          </InputGroup>
        </FormControl>
        <Wrap mt="35px" w="full" justify="center">
          <IButton
            text="המשך"
            isDisabled={
              isLoading ||
              !firstPassword ||
              !secondPassword ||
              firstPassword.length < 8 ||
              secondPassword.length < 8 ||
              firstPassword !== secondPassword
            }
            onClick={handleClick}
          />
        </Wrap>
      </Center>
    </Center>
  );
};

const labelStyle: FormLabelProps = {
  color: 'brand.yellow',
  fontSize: '16px',
  htmlFor: 'email',
  m: '0 20px 15px 0',
};

const inputStyle: InputProps = {
  variant: 'first',
  fontSize: '18px',
  color: 'brand.white',
};

export default LoginThirdForm;
