import { IUser } from './../models/IUser/IUser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userApi } from '../services/user';
import { RootState } from './store';

export type UserRole =
  | 'noam'
  | 'agent'
  | 'manager'
  | 'driver'
  | 'tiful'
  | 'office_order'
  | 'none';
interface IUserState {
  info: IUser;
  isLoading: boolean;
  error: string;
  notificationListener: boolean;
  driverReceives?: string;
}

const initialState: IUserState = {
  info: {
    role: 'none',
    api_token: '',
    email: '',
    full_name: '',
    profile_type: '',
    profile_id: 0,
    unread_notifications: 0,
  },
  isLoading: false,
  notificationListener: false,
  error: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.info = action.payload;
    },
    setDriverReceives: (state, action: PayloadAction<string>) => {
      state.driverReceives = action.payload;
    },
    setUserRole: (state, action: PayloadAction<UserRole>) => {
      state.info.role = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.info.email = action.payload;
    },
    logout: (state) => {
      state.info = initialState.info;
    },
    connectMessaging: () => {},
    onNotificationMessage: (state) => {
      state.notificationListener = !state.notificationListener;
      // state.info = initialState.info;
    },
  },
  extraReducers: (builder) => {
    //LOGIN RESPONSE DATA
    builder
      .addMatcher(
        userApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.info = payload;
        }
      )
      .addMatcher(
        userApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.info.profile_id = payload.profile_id;
        }
      )
      .addMatcher(
        userApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.info.full_name = payload.full_name;
        }
      );
  },
});

export const selectUser = (state: RootState) => state.user.info.role;
export const selectNotificationListener = (state: RootState) =>
  state.user.notificationListener;
export const selectProfile = (state: RootState) => state.user.info;
export const selectProfileID = (state: RootState) => state.user.info.profile_id;
export const selectDriverReceives = (state: RootState) =>
  state.user.driverReceives;

export const {
  setUser,
  setUserRole,
  logout,
  setDriverReceives,
  connectMessaging,
  setUserEmail,
  onNotificationMessage,
} = userSlice.actions;

export default userSlice.reducer;
