import React, { useState } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

interface Props extends ButtonProps {
  text: string;
  isError?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

const IButton = ({ text, isError, onClick, isLoading, ...props }: Props) => {
  return (
    <Button
      onClick={onClick}
      variant="first"
      maxW="345px"
      border="1px solid white"
      color="brand.white"
      isLoading={isLoading}
      // disabled={isError}
      bg={isError ? "brand.border" : "brand.bordo"}
      {...props}
    >
      {text}
    </Button>
  );
};

export default IButton;
