import {
  CalculateOrder,
  CreateOrderResponse,
  FullOrderItem,
  ICreateOrder,
  IFullOrder,
  InitOrder,
  INotYetOrdered,
  IReturnOrderListResponse,
  ISpecialOffersOrders,
  IStatisticsClientOrders,
  OrderCalculation,
  OrderLine,
  OrderProductResponse,
  OrderReturnProductsResponse,
  ReturnItem,
  StatisticsClientOrdersResponse,
  UpdateOrderProduct,
} from './../models/IOrder/IOrder';
import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IOrderListResponse } from '../models/IOrder/IOrder';

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  tagTypes: ['Order'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getOrders: build.query<
      IOrderListResponse,
      {
        search: string;
        page: number;
        from_date: string;
        to_date: string;
        area_id: string;
        agent_id: string;
        status_id: string;
        days: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: IOrderListResponse }) =>
        response.data,
      query: ({
        search,
        page = 1,
        from_date,
        to_date,
        area_id,
        agent_id,
        status_id,
        days,
      }) => ({
        url: '/api/orders',
        method: 'GET',
        params: {
          search,
          page,
          from_date,
          to_date,
          area_id,
          agent_id,
          status_id,
          days: JSON.stringify(days),
        },
      }),
      providesTags: (result) => ['Order'],
    }),
    getOrderProducts: build.query<
      OrderProductResponse,
      {
        categoryId: number | null;
        page?: number;
        search: string;
        business_id: string;
      }
    >({
      transformResponse: (response: { data: OrderProductResponse }) =>
        response.data,
      query: ({ categoryId, page = 1, search, business_id }) => ({
        url: `/api/orders/products`,
        method: 'GET',
        params: {
          category_id: categoryId || '',
          search,
          business_id,
        },
      }),
    }),
    getOrderLines: build.query<OrderLine[], string>({
      transformResponse: (response: { data: OrderLine[] }) => response.data,
      query: () => ({
        url: `/api/orders/lines`,
        method: 'GET',
      }),
    }),
    statisticsClientOrders: build.query<
      StatisticsClientOrdersResponse,
      { id: string; from_date: string; to_date: string; page: number }
    >({
      transformResponse: (response: { data: StatisticsClientOrdersResponse }) =>
        response.data,
      query: ({ id, from_date, to_date, page }) => ({
        url: `/api/agents/${id}/statistics-client-orders`,
        method: 'GET',
        params: { from_date, to_date, page },
      }),
    }),
    initOrder: build.query<InitOrder, string>({
      transformResponse: (response: { data: InitOrder }) => response.data,
      query: (business_id) => ({
        url: `/api/orders/init`,
        method: 'GET',
        params: {
          business_id,
        },
      }),
    }),
    getOrder: build.query<IFullOrder, string>({
      transformResponse: (response: { data: IFullOrder }) => response.data,
      query: (orderId) => ({
        url: `/api/orders/${orderId}`,
        method: 'GET',
      }),
    }),
    calculateOrderPrice: build.mutation<OrderCalculation, CalculateOrder>({
      transformResponse: (response: { data: OrderCalculation }) =>
        response.data,
      query: (productData) => ({
        url: '/api/orders/calculation',
        method: 'POST',
        body: productData,
      }),
    }),
    createOrder: build.mutation<CreateOrderResponse, ICreateOrder>({
      transformResponse: (response: { data: CreateOrderResponse }) =>
        response.data,
      query: (orderData) => ({
        url: '/api/orders',
        method: 'POST',
        body: orderData,
      }),
    }),
    updateOrder: build.mutation<
      CreateOrderResponse,
      { data: Omit<ICreateOrder, 'products'>; orderId: string }
    >({
      transformResponse: (response: { data: CreateOrderResponse }) =>
        response.data,
      query: ({ data, orderId }) => ({
        url: `/api/orders/${orderId}`,
        method: 'POST',
        body: data,
      }),
    }),
    changeOrderStatus: build.mutation<
      any,
      { orderId: string; reject?: string; status_id: number }
    >({
      query: ({ reject, orderId, status_id }) => ({
        url: `/api/orders/${orderId}/change-status`,
        method: 'POST',
        body: {
          reject,
          status_id,
        },
      }),
    }),
    getPreviousProducts: build.mutation<FullOrderItem[], string>({
      transformResponse: (response: { data: FullOrderItem[] }) => response.data,
      query: (business_id) => ({
        url: '/api/orders/previous-product',
        method: 'GET',
        params: {
          business_id,
        },
      }),
    }),
    getOrdersHistory: build.query<
      IOrderListResponse,
      {
        search: string;
        page: number;
        from_date: string;
        to_date: string;
        area_id: string;
        agent_id: string;
        status_id: string;
        days: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: IOrderListResponse }) =>
        response.data,
      query: ({
        search,
        page = 1,
        from_date,
        to_date,
        area_id,
        agent_id,
        status_id,
        days,
      }) => ({
        url: '/api/orders/history',
        method: 'GET',
        params: {
          search,
          page,
          from_date,
          to_date,
          area_id,
          agent_id,
          status_id,
          days: JSON.stringify(days),
        },
      }),
    }),
    getFutureOrders: build.query<
      IOrderListResponse,
      {
        search: string;
        page: number;
        from_date: string;
        to_date: string;
        area_id: string;
        agent_id: string;
        status_id: string;
        days: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: IOrderListResponse }) =>
        response.data,
      query: ({
        search,
        page = 1,
        from_date,
        to_date,
        area_id,
        agent_id,
        status_id,
        days,
      }) => ({
        url: '/api/orders/future',
        method: 'GET',
        params: {
          search,
          page,
          from_date,
          to_date,
          area_id,
          agent_id,
          status_id,
          days: JSON.stringify(days),
        },
      }),
    }),
    createProductsReturns: build.mutation<
      OrderReturnProductsResponse,
      { orderId: string; data: UpdateOrderProduct }
    >({
      query: ({ orderId, data }) => ({
        url: `/api/orders/${orderId}/return-items`,
        method: 'POST',
        body: data,
      }),
    }),
    updateOrderProducts: build.mutation<
      any,
      { orderId: string; data: UpdateOrderProduct }
    >({
      query: ({ orderId, data }) => ({
        url: `/api/orders/${orderId}/change-products`,
        method: 'POST',
        body: data,
      }),
    }),
    notYetOrdered: build.query<
      INotYetOrdered[],
      {
        search: string;

        from_date: string;
        to_date: string;
        area_id: string;
        agent_id: string;
        status_id: string;
        days: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: INotYetOrdered[] }) =>
        response.data,
      query: ({
        search,
        from_date,
        to_date,
        area_id,
        agent_id,
        status_id,
        days,
      }) => ({
        url: '/api/orders/not-yet-ordered',
        method: 'GET',
        params: {
          search,
          from_date,
          to_date,
          area_id,
          agent_id,
          status_id,
          days: JSON.stringify(days),
        },
      }),
    }),
    orderReminder: build.mutation<any, number>({
      query: (id) => ({
        url: `/api/clients/${id}/order-reminder`,
        method: 'POST',
      }),
    }),
    returnOrderList: build.query<
      IReturnOrderListResponse,
      {
        search: string;
        from_date: string;
        to_date: string;
        area_id: string;
        page: number;
      }
    >({
      transformResponse: (response: { data: IReturnOrderListResponse }) =>
        response.data,
      query: ({ search, from_date, to_date, area_id, page }) => ({
        url: '/api/returns',
        method: 'GET',
        params: { search, from_date, to_date, area_id, page },
      }),
    }),
    confirmFreeShipping: build.mutation<
      any,
      { orderId: string; is_free_shipping: number }
    >({
      query: ({ is_free_shipping, orderId }) => ({
        url: `/api/orders/${orderId}/free-shipping`,
        method: 'POST',
        body: {
          is_free_shipping,
        },
      }),
    }),
    acceptPaidOrder: build.mutation<any, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `/api/orders/${orderId}/paid`,
        method: 'POST',
      }),
    }),
    getReturnItem: build.query<ReturnItem, string>({
      transformResponse: (response: { data: ReturnItem }) => response.data,
      query: (return_id) => ({
        url: `/api/returns/${return_id}`,
        method: 'GET',
      }),
    }),
    specialOffersOrders: build.query<ISpecialOffersOrders[], string>({
      transformResponse: (response: { data: ISpecialOffersOrders[] }) =>
        response.data,
      query: (business_id) => ({
        url: '/api/orders/special-offers',
        method: 'GET',
        params: { business_id },
      }),
    }),
  }),
});
