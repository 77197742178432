import { questionsApi } from './../services/questions';
import persistStore from 'redux-persist/es/persistStore';
import { agentsApi } from './../services/agents';
import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';

import { reportsApi } from '../services/reports';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { userApi } from '../services/user';
import homeReducer from './homeSlice';
import userReducer from './userSlice';
import clientReducer from './clientSlice';
import orderReducer from './ordersSlice';
import returnOrderReducer from './returnOrdersSlice';
import { productsApi } from '../services/products';
import { linesApi } from '../services/lines';
import { driversApi } from '../services/drivers';
import { rtkQueryErrorLogger } from '../middlewares/apiError';
import { vehiclesApi } from '../services/vehicles';
import { clientsApi } from '../services/clients';
import { ordersApi } from '../services/orders';
import { tifulTasksApi } from '../services/tifulTasks';
import { notificationMiddleware } from '../middlewares/notifications/middleware';
import { notificationsApi } from '../services/notification';
import { dashboardApi } from '../services/dashboard';
import { areasApi } from '../services/areas';
import { specialOfferApi } from '../services/specialOffer';

const rootReducer = combineReducers({
  home: homeReducer,
  user: userReducer,
  client: clientReducer,
  order: orderReducer,
  returnOrder: returnOrderReducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [agentsApi.reducerPath]: agentsApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [linesApi.reducerPath]: linesApi.reducer,
  [driversApi.reducerPath]: driversApi.reducer,
  [vehiclesApi.reducerPath]: vehiclesApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [tifulTasksApi.reducerPath]: tifulTasksApi.reducer,
  [specialOfferApi.reducerPath]: specialOfferApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [areasApi.reducerPath]: areasApi.reducer,
  [questionsApi.reducerPath]: questionsApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const setupStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      userApi.middleware,
      rtkQueryErrorLogger,
      notificationMiddleware,
      driversApi.middleware,
    ],
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const store = setupStore();
export const persistor = persistStore(store);
