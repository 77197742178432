import { useMediaQuery } from '@chakra-ui/react';

const useTablet = () => {
  const [isTabletVerticalWidth] = useMediaQuery('(min-width: 0px)');
  const [isTabletHorizontalWidth] = useMediaQuery('(max-width: 1024px)');
  const isTablet = isTabletVerticalWidth && isTabletHorizontalWidth;
  return isTablet;
};

export default useTablet;
