import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface Home {
  isLoading: boolean;
  menuIsOpened: boolean;
  currentWidth: number;
}

const initialState: Home = {
  isLoading: false,
  menuIsOpened: true,
  currentWidth: 0,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsOpenMenu: (state, action: PayloadAction<boolean>) => {
      state.menuIsOpened = action.payload;
    },
    setCurrentWidth: (state, action: PayloadAction<number>) => {
      state.currentWidth = action.payload;
    },
  },
});

export const { setCurrentWidth } = homeSlice.actions;

export const selectCurrentWidth = (state: RootState) => state.home.currentWidth;
export const { setIsLoading, setIsOpenMenu } = homeSlice.actions;

export default homeSlice.reducer;
