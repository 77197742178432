import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IVehicle } from '../models/IVehicle/IVehicle';

export const vehiclesApi = createApi({
  reducerPath: 'vehiclesApi',
  tagTypes: ['Vehicle'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getVehicles: build.query<IVehicle[], { query: string; line_id: string }>({
      transformResponse: (response: { data: { data: IVehicle[] } }) =>
        response.data.data,
      query: ({ query, line_id }) => ({
        url: '/api/vehicles',
        method: 'GET',
        params: {
          search: query,
          line_id,
        },
      }),
      providesTags: (result) => ['Vehicle'],
    }),
    createVehicle: build.mutation<IVehicle[], IVehicle>({
      transformResponse: (response: { data: { data: IVehicle[] } }) =>
        response.data.data,
      query: (newVehicle) => ({
        url: '/api/vehicles',
        method: 'POST',
        body: newVehicle,
      }),
      invalidatesTags: ['Vehicle'],
    }),
    updateVehicle: build.mutation<IVehicle[], IVehicle>({
      transformResponse: (response: { data: { data: IVehicle[] } }) =>
        response.data.data,
      query: (newVehicle) => ({
        url: `/api/vehicles/${newVehicle.id}`,
        method: 'POST',
        body: newVehicle,
      }),
      invalidatesTags: ['Vehicle'],
    }),
    deleteVehicle: build.mutation<IVehicle[], number>({
      query: (id) => ({
        url: `/api/vehicles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Vehicle'],
    }),
  }),
});
