import {
  Box,
  Button,
  ButtonProps,
  Center,
  CenterProps,
  Flex,
  FlexProps,
  Image,
  ImageProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { mdText, smText, TwoXLBoldText } from '../../../../theme';
import calendarIcon from '../../../../Images/SVG/event1.svg';
import directionIcn from '../../../../Images/SVG/direction.svg';
import { ChevronLeftIcon, CloseIcon } from '@chakra-ui/icons';
import newIcn from '../../../../Images/SVG/new-icn.svg';
import { INotification } from '../../../../models/INotification/INotification';
import { notificationsApi } from '../../../../services/notification';
import ModalSurvey from '../../ModalSurvey/ModalSurvey';
import { useCallback, useState } from 'react';
import { notificationTypes } from '../../../../constants/constants';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector } from '../../../../hooks/redux';
import moment from 'moment';
import { toDateTime } from '../../../../helpers/common';
import { paths } from '../../../../routes/paths';

interface Props {
  notification: INotification;
  refetchNotifications?: () => void;
  onCloseNotification?: () => void;
  autoWidth?: boolean;
  white?: boolean;
  fullWidth?: boolean;
}

const NotificationItem = ({
  refetchNotifications = () => {},
  notification,
  autoWidth = false,
  white = false,
  fullWidth = false,
  onCloseNotification = () => {},
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = useAppSelector((state) => state.user.info.role);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [questionId, setQuestionId] = useState<string | number>();
  const [deleteNotification, { isLoading: isLoadingDeleting }] =
    notificationsApi.useDeleteNotificationMutation();

  const handleDelete = async (id: string) => {
    await deleteNotification(id);
    refetchNotifications();
  };

  const wrapperWidth = autoWidth ? 'full' : '900px';
  const backgroundColor = white ? 'brand.white' : 'transparent';

  const handleClickToAnswer = (id: string | number) => {
    setQuestionId(id);
    if (id) onOpen();
  };

  const handleRedirect = useCallback(() => {
    if (notification.type === notificationTypes.BLOCKED) {
      navigate(
        `/${userRole}/client/in-client/home/${notification.business_id}`
      );
    }
    if (notification.type === notificationTypes.NEW_ORDER) {
      if (userRole === 'driver') {
        navigate(`/${userRole}${paths.inDriverTask}/${notification.order.id}`);
      } else {
        navigate(
          `/${userRole}${paths.ordersNew}/${notification.order.status_id}?id=${notification.order.id}&businessId=${notification.order.business_id}`,
          { state: { from: location.pathname } }
        );
      }
    }
    onCloseNotification();
  }, [
    notification,
    userRole,
    location.pathname,
    navigate,
    onCloseNotification,
  ]);

  return (
    <>
      <ModalSurvey isOpen={isOpen} id={questionId} onClose={onClose} />
      <Flex
        {...wrapper}
        w={fullWidth ? 'full' : wrapperWidth}
        bg={backgroundColor}
        maxW={fullWidth ? '100vw' : '900px'}
        opacity={isLoadingDeleting ? '0.4' : '1'}
        pointerEvents={isLoadingDeleting ? 'none' : 'all'}
      >
        {!notification.is_read && <Image {...newImg} />}
        <Flex flexDir='column' w='full'>
          <Flex justify='space-between' w='full' align='center'>
            <Text {...TwoXLBoldText}>{notification.title}</Text>
            <Box textAlign='left'>
              <Flex>
                <Image src={calendarIcon} alt='calendar' ml='4px' />
                <Text {...smText}>
                  {typeof notification?.created_at === 'number' &&
                    moment(notification.created_at).format('DD/MM/YYYY HH:mm')}
                  {typeof notification.created_at === 'string' &&
                    notification.created_at}
                </Text>
              </Flex>
              <Text {...smText}>{notification.time}</Text>
            </Box>
          </Flex>
          <Text {...mdText} mt='15px'>
            {notification.message}
          </Text>
          <Flex w='full' justify='flex-end' mt='auto'>
            {notification.type === 'question' ? (
              <Button
                {...secondBtn}
                onClick={() => handleClickToAnswer(notification.question_id)}
              >
                לענות
                <Image mr='10px' src={directionIcn} alt='direction' />
              </Button>
            ) : (
              <>
                {notification.type && (
                  <Button {...btnStyle} onClick={handleRedirect}>
                    פרטים מלאים <ChevronLeftIcon w='27px' h='27px' />
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Flex>
        <Flex>
          <Center
            {...closeBtn}
            _hover={{ cursor: 'pointer' }}
            onClick={() => handleDelete(notification.id)}
          >
            <CloseIcon w='8px' h='8px' color='brand.white' />
          </Center>
        </Flex>
      </Flex>
    </>
  );
};

const secondBtn: ButtonProps = {
  w: '90px',
  h: '36px',
  borderRadius: '8px',
  variant: 'solid',
  bg: 'brand.sky',
  fontSize: '12px',
  fontWeight: 'normal',
  _hover: { bg: '' },
};

const newImg: ImageProps = {
  src: newIcn,
  alt: 'new-img',
  pos: 'absolute',
  left: '175px',
  top: '-7px',
};

const closeBtn: CenterProps = {
  mr: '30px',
  w: '25px',
  h: '25px',
  borderRadius: '50%',
  bg: 'brand.bordo',
};

const btnStyle: ButtonProps = {
  w: '107px',
  h: '32px',
  borderRadius: '200px',
  fontSize: '12px',
  fontWeight: 'normal',
  bg: 'brand.white',
  color: 'brand.text2',
  border: '1px solid',
  borderColor: 'brand.border4',
};

const wrapper: FlexProps = {
  _first: { mt: '6px' },
  mt: '18px',
  maxW: '900px',
  w: 'full',
  minW: '500px',
  minH: '140px',
  border: '1px solid',
  borderColor: 'brand.border3',
  borderRadius: '14px',
  pos: 'relative',
  p: '15px 40px 20px 20px',
};

export default NotificationItem;
