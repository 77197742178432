import {
  Button,
  ButtonProps,
  Center,
  Flex,
  FlexProps,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  Radio,
  RadioGroup,
  RadioProps,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { lgText, smText, TwoXLBoldText } from '../../../theme';
import icon from '../../../Images/SVG/event1.svg';
import directionIcn from '../../../Images/SVG/direction.svg';
import useModalSurvey from './useModalSurvey';
import moment from 'moment';
import { toDateTime } from '../../../helpers/common';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id?: string | number;
}

const ModalSurvey = ({ isOpen, onClose, id }: Props) => {
  const {
    question,
    setFirstRadioValue,
    firstRadioValue,
    firstRadioOptions,
    handleClickSendReply,
    isLoading,
    isLoadingQuestions,
  } = useModalSurvey({ id, onClose });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent {...content}>
        <Flex w="full" justify="space-between">
          <ModalCloseButton {...modalClose} size="17px" />
          <Text pr="50px" {...TwoXLBoldText}>
            {question?.name ? question.name : 'שאלון חדש'}
          </Text>
          <Flex flexDir="column" align="flex-end">
            <Flex>
              <Image src={icon} alt="icon" ml="6px" />
              <Text {...smText}>
                {moment(toDateTime(Number(question?.created_at))).format(
                  'DD/MM/YYYY'
                )}
              </Text>
            </Flex>
            <Text {...smText}>
              {moment(toDateTime(Number(question?.created_at))).format('HH:mm')}
            </Text>
          </Flex>
        </Flex>
        <ModalBody>
          <Center flexDir="column" h="full">
            <Flex w="full" {...lgText} mt="45px" mb="50px">
              <Text>שאלה:</Text>
              <Text pr="5px">{question?.question}</Text>
            </Flex>
            <Flex {...wrapRadioValue}>
              <RadioGroup
                maxW="275px"
                w="full"
                isDisabled={
                  !question?.status ||
                  Boolean(
                    question?.variables.find((item) => item.is_answer === true)
                      ?.id
                  )
                }
                onChange={setFirstRadioValue}
                value={
                  question?.variables.find((item) => item.is_answer === true)
                    ?.id || firstRadioValue
                }
              >
                <HStack
                  spacing="30px"
                  flexDir="column"
                  m="0"
                  align="flex-start"
                >
                  {firstRadioOptions.map((option: any) => (
                    <HStack
                      {...radioStackStyle}
                      onClick={() => setFirstRadioValue(option.value)}
                    >
                      <Radio value={option.value} {...radioBtnStyle(false)} />
                      <Text {...primaryText}>{option.label}</Text>
                    </HStack>
                  ))}
                </HStack>
              </RadioGroup>
            </Flex>
            <Button
              {...secondBtn}
              isDisabled={
                firstRadioValue === '' ||
                isLoading ||
                isLoadingQuestions ||
                !question?.status ||
                Boolean(
                  question?.variables.find((item) => item.is_answer === true)
                    ?.id
                )
              }
              onClick={handleClickSendReply}
            >
              שלח תשובה
              <Image mr="10px" src={directionIcn} alt="direction" />
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const wrapRadioValue: FlexProps = {
  h: '300px',
  maxW: '275px',
  w: 'full',
  overflowY: 'auto',
  overflowX: 'hidden',
  p: '5px 5px 0 0',
};

const radioBtnStyle = (colour?: boolean) =>
  ({
    w: '16px',
    h: '16px',
    border: '1px solid #BDBDBD',
    position: 'relative',
    bg: 'white',
    _checked: {
      border: colour ? '1px solid #4990D2' : '1px solid #891308',
      _after: {
        content: '""',
        position: 'absolute',
        w: '10px',
        h: '10px',
        borderRadius: '50%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bg: colour ? 'brand.sky' : '#891308',
      },
    },
  } as RadioProps);

const radioStackStyle: StackProps = {
  m: '0 0 35px 0 !important',
  _hover: {
    cursor: 'pointer',
  },
};

const primaryText: TextProps = {
  fontSize: '14px',
  lineHeight: '18px',
  color: 'brand.black',
};

const secondBtn: ButtonProps = {
  w: '280px',
  h: '50px',
  borderRadius: '15px',
  variant: 'solid',
  bg: 'brand.bordo',
  fontSize: '16px',
  fontWeight: 'normal',
  mt: 'auto',
  _hover: { bg: '' },
};

const content: ModalContentProps = {
  h: '630px',
  w: '375px',
  borderRadius: '40px !important',
  m: 'auto',
  p: '25px',
};

const modalClose: ModalContentProps = {
  _focus: { boxShadow: 'none' },
  _hover: { bg: 'transparent' },
  color: 'brand.black',
  m: '25px',
};

export default ModalSurvey;
