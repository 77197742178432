import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  ICreateDriver,
  IDriver,
  IDriverListResponse,
  IDriverPriority,
  IDriverPriorityResponse,
  IDriverResponse,
  IPrioritizeDriverListResponse,
  IShowOrderForDriver,
} from '../models/IDriver/IDriver';

export const driversApi = createApi({
  reducerPath: 'driversApi',
  tagTypes: ['Driver'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    listOrdersDriver: build.query<
      IDriverListResponse,
      {
        search: string;
        agent_id: string;
        city_id: string;
        days: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: IDriverListResponse }) =>
        response.data,
      query: ({ search, agent_id, city_id, days }) => ({
        url: '/api/driver/orders',
        method: 'GET',
        params: {
          search,
          agent_id,
          city_id,
          days: JSON.stringify(days),
        },
      }),
      providesTags: () => ['Driver'],
    }),
    listOrdersDeliveredDriver: build.query<
      IDriverListResponse,
      {
        search: string;
        agent_id: string;
        city_id: string;
        days: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: IDriverListResponse }) =>
        response.data,
      query: ({ search, agent_id, city_id, days }) => ({
        url: '/api/driver/orders/delivered',
        method: 'GET',
        params: {
          search,
          agent_id,
          city_id,
          days: JSON.stringify(days),
        },
      }),
      providesTags: () => ['Driver'],
    }),
    getDrivers: build.query<
      IDriverResponse,
      { search: string; line_id: string }
    >({
      transformResponse: (response: { data: IDriverResponse }) => response.data,
      query: ({ search, line_id }) => ({
        url: '/api/drivers',
        method: 'GET',
        params: {
          search,
          line_id,
        },
      }),
      providesTags: () => ['Driver'],
    }),
    showOrderForDriver: build.query<
      IShowOrderForDriver,
      { search: string; id: string | undefined }
    >({
      transformResponse: (response: { data: IShowOrderForDriver }) =>
        response.data,
      query: ({ search, id }) => ({
        url: `/api/driver/orders/${id}`,
        method: 'GET',
        params: {
          search,
        },
      }),
      providesTags: () => ['Driver'],
    }),
    driverGoesToTheClient: build.mutation<any, number>({
      query: (id) => {
        return {
          url: `/api/driver/orders/${id}/start-delivery`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Driver'],
    }),
    returnProductOrder: build.mutation<any, { id: number; formData: any }>({
      query: ({ id, formData }) => {
        return {
          url: `/api/driver/orders/${id}/return-items`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Driver'],
    }),
    driverDelivered: build.mutation<any, number>({
      query: (id) => {
        return {
          url: `/api/driver/orders/${id}/end-delivery`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Driver'],
    }),
    createDriver: build.mutation<IDriver, ICreateDriver>({
      query: (newDriver) => {
        return {
          url: '/api/drivers',
          method: 'POST',
          body: newDriver,
        };
      },
      invalidatesTags: ['Driver'],
    }),
    updateDriver: build.mutation<IDriver, ICreateDriver>({
      query: (driver) => {
        return {
          url: `/api/drivers/${driver.id}`,
          method: 'POST',
          body: driver,
        };
      },
      invalidatesTags: ['Driver'],
    }),
    deleteDriver: build.mutation<any, { driver_id: number }>({
      query: ({ driver_id }) => {
        return {
          url: `/api/drivers/${driver_id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Driver'],
    }),
    changeStatusNoteDriver: build.mutation<
      IShowOrderForDriver,
      { id: number; note_id: number; is_done: number }
    >({
      transformResponse: (response: { data: IShowOrderForDriver }) =>
        response.data,
      query: ({ id, note_id, is_done }) => {
        return {
          url: `/api/driver/orders/${id}/notes/${note_id}/change-status`,
          method: 'POST',
          body: { is_done },
        };
      },
      invalidatesTags: ['Driver'],
    }),
    addCommentToNoteDriver: build.mutation<
      IShowOrderForDriver,
      { id: number; note_id: number; comment: string }
    >({
      transformResponse: (response: { data: IShowOrderForDriver }) =>
        response.data,
      query: ({ id, note_id, comment }) => {
        return {
          url: `/api/driver/orders/${id}/notes/${note_id}/comment`,
          method: 'POST',
          body: { comment },
        };
      },
      invalidatesTags: ['Driver'],
    }),
    uploadImageToNote: build.mutation<
      IShowOrderForDriver,
      { formData: any; id: number; note_id: number }
    >({
      transformResponse: (response: { data: IShowOrderForDriver }) =>
        response.data,
      query: ({ formData, id, note_id }) => ({
        url: `/api/driver/orders/${id}/notes/${note_id}/upload-image`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteImageToNote: build.mutation<
      IShowOrderForDriver,
      { id: number; note_id: number; note_order_image_id: number }
    >({
      transformResponse: (response: { data: IShowOrderForDriver }) =>
        response.data,
      query: ({ id, note_id, note_order_image_id }) => ({
        url: `/api/driver/orders/${id}/notes/${note_id}/upload-image/${note_order_image_id}`,
        method: 'DELETE',
      }),
    }),
    prioritizeDriverList: build.query<
      IPrioritizeDriverListResponse,
      { search: string; line_id: string; page: number }
    >({
      transformResponse: (response: { data: IPrioritizeDriverListResponse }) =>
        response.data,
      query: ({ search, line_id, page }) => ({
        url: '/api/drivers/prioritize',
        method: 'GET',
        params: {
          search,
          line_id,
          page,
        },
      }),
    }),
    RequestFreeShipping: build.mutation<IShowOrderForDriver, { id: number }>({
      transformResponse: (response: { data: IShowOrderForDriver }) =>
        response.data,
      query: ({ id }) => ({
        url: `/api/driver/orders/${id}/request-free-shipping`,
        method: 'POST',
      }),
    }),
    TakeCash: build.mutation<any, { id: number; formData: any }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ id, formData }) => ({
        url: `/api/driver/orders/${id}/cash`,
        method: 'POST',
        body: formData,
      }),
    }),
    SaveSignature: build.mutation<any, { formData: any; id: string }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ formData, id }) => ({
        url: `/api/driver/orders/${id}/signature`,
        method: 'POST',
        body: formData,
      }),
    }),
    driversPrioritize: build.mutation<
      any,
      { formData: IDriverPriority[]; id: string }
    >({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ id, formData }) => ({
        url: `/api/drivers/prioritize/${id}`,
        method: 'POST',
        body: formData,
      }),
    }),
    receiveDriversPrioritize: build.query<
      IDriverPriorityResponse[],
      {
        search: string;
        from_date: string;
        to_date: string;
        area_id: number;
        state_id: number;
        city_id: string;
        days: any;
        id: number;
      }
    >({
      transformResponse: (response: { data: IDriverPriorityResponse[] }) =>
        response.data,
      query: ({
        search,
        from_date,
        to_date,
        area_id,
        state_id,
        days,
        id,
        city_id,
      }) => ({
        url: `/api/drivers/prioritize/${id}`,
        method: 'GET',
        params: {
          search,
          from_date,
          city_id,
          to_date,
          area_id,
          state_id,
          days: JSON.stringify(days),
        },
      }),
    }),
  }),
});
