import { ordersApi } from './../services/orders';
import {
  IFullOrder,
  IOrderSpecialOffer,
  OrderProduct,
} from './../models/IOrder/IOrder';
import { RootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SelfServiceData {
  owner_address: string;
  owner_name: string;
  owner_lastname: string;
  owner_id_card: string;
  owner_phone: string;
  operation_note: string;
  office_note: string;
  created_at: string;
}
interface OrderState {
  products: OrderProduct[];
  return_products: OrderProduct[];
  order_special_items: IOrderSpecialOffer[];
  saved: boolean;
  selfServiceData: SelfServiceData;
}

const initialState: OrderState = {
  saved: false,
  selfServiceData: {
    operation_note: '',
    office_note: '',
    owner_address: '',
    owner_name: '',
    owner_lastname: '',
    owner_id_card: '',
    owner_phone: '',
    created_at: '',
  },
  products: [],
  return_products: [],
  order_special_items: [],
};

export const ordersSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    saveProducts: (state) => {
      state.saved = true;
    },
    saveSelfServiceData: (state, action: PayloadAction<SelfServiceData>) => {
      state.selfServiceData = action.payload;
    },
    resetSelfServiceData: (state) => {
      state.selfServiceData = initialState.selfServiceData;
    },
    resetProducts: (state) => {
      state.products = initialState.products;
    },
    addOrderProduct: (
      state,
      action: PayloadAction<{ product: OrderProduct }>
    ) => {
      const { product } = action.payload;
      state.products = [...state.products, { ...product, count: 1 }];
    },
    addReturnProduct: (
      state,
      action: PayloadAction<{ product: OrderProduct; reason: string }>
    ) => {
      const { product, reason } = action.payload;
      state.return_products = [
        ...state.return_products,
        {
          ...product,
          count: 1,
          reason,
        },
      ];
    },
    changeProductReason: (
      state,
      action: PayloadAction<{ id: number; reason: string }>
    ) => {
      const { id, reason } = action.payload;
      state.return_products = state.return_products.map((product) => {
        if (product.id === id) {
          return { ...product, reason };
        }
        return product;
      });
    },
    toggleAmountReason: (
      state,
      action: PayloadAction<{ id: number; value: 0 | 1 }>
    ) => {
      const { id, value } = action.payload;
      let nextState = [];
      let deleteItem = false;
      nextState = state.return_products.map((product) => {
        if (product.id === id) {
          if (!product.is_reason_damaged && value === 0) deleteItem = true;
          return {
            ...product,
            is_reason_amount: value,
          };
        }
        return product;
      });
      if (deleteItem) {
        state.return_products = nextState.filter((prev) => prev.id !== id);
      } else {
        state.return_products = nextState;
      }
    },
    toggleDamagedReason: (
      state,
      action: PayloadAction<{ id: number; value: 0 | 1 }>
    ) => {
      const { id, value } = action.payload;
      let nextState = [];
      let deleteItem = false;
      nextState = state.return_products.map((product) => {
        if (product.id === id) {
          if (!product.is_reason_amount && value === 0) deleteItem = true;
          return {
            ...product,
            is_reason_damaged: value,
          };
        }
        return product;
      });
      if (deleteItem) {
        state.return_products = nextState.filter((prev) => prev.id !== id);
      } else {
        state.return_products = nextState;
      }
    },
    deleteReturnProduct: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.return_products = state.return_products.filter(
        (product) => product.id !== id
      );
    },
    deleteOrderProduct: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.products = state.products.filter((product) => product.id !== id);
    },
    increaseProductCount: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      state.products = state.products.map((product) =>
        product.id === id
          ? { ...product, count: product.count && product.count + 1 }
          : product
      );
    },
    increaseReturnProductCount: (
      state,
      action: PayloadAction<{ id: number }>
    ) => {
      const { id } = action.payload;
      state.return_products = state.return_products.map((product) =>
        product.id === id
          ? { ...product, count: product.count && product.count + 1 }
          : product
      );
    },
    changeProductCount: (
      state,
      action: PayloadAction<{ id: number; count: string }>
    ) => {
      const { id, count } = action.payload;
      state.products = state.products.map((product) =>
        product.id === id ? { ...product, count: Number(count) } : product
      );
    },
    addSpecialOffer: (state, action: PayloadAction<IOrderSpecialOffer>) => {
      state.order_special_items = state.order_special_items.concat([
        action.payload,
      ]);
    },
    toggleMissingInStock: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      state.products = state.products.map((product) =>
        product.id === id
          ? { ...product, is_missing_stock: !product.is_missing_stock }
          : product
      );
    },
    changeReturnProductCount: (
      state,
      action: PayloadAction<{ id: number; count: string }>
    ) => {
      const { id, count } = action.payload;
      state.return_products = state.return_products.map((product) =>
        product.id === id ? { ...product, count: Number(count) } : product
      );
    },
    decreaseProductCount: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const isMinimum =
        state.products.find((product) => product.id === id)?.count === 1;

      state.products = !isMinimum
        ? state.products.map((product) =>
            product.id === id
              ? { ...product, count: product.count && product.count - 1 }
              : product
          )
        : state.products.filter((product) => product.id !== id);
    },
    decreaseReturnProductCount: (
      state,
      action: PayloadAction<{ id: number }>
    ) => {
      const { id } = action.payload;
      const isMinimum =
        state.return_products.find((product) => product.id === id)?.count === 1;

      state.return_products = !isMinimum
        ? state.return_products.map((product) =>
            product.id === id
              ? { ...product, count: product.count && product.count - 1 }
              : product
          )
        : state.return_products.filter((product) => product.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        ordersApi.endpoints.getOrder.matchFulfilled,
        (state, action: PayloadAction<IFullOrder>) => {
          const { payload } = action;
          state.products = payload.order_items.map((item) => ({
            ...item.product,
            count: item.count,
            is_missing_stock: item.is_missing_stock,
            business_price: item.price,
          }));
          state.return_products = payload.return_order_items.map((item) => ({
            ...item.product,
            reason: item.reason,
            is_reason_amount: item.is_reason_amount,
            is_reason_damaged: item.is_reason_damaged,
            count: item.count,
            business_price: item.price,
          }));
        }
      )
      .addMatcher(
        ordersApi.endpoints.getOrder.matchFulfilled,
        (state, action) => {
          const { payload } = action;
          state.order_special_items = payload.order_special_items || [];
        }
      )
      .addMatcher(
        ordersApi.endpoints.getPreviousProducts.matchFulfilled,
        (state, action) => {
          const { payload } = action;
          state.products = payload.map((item) => ({
            ...item.product,
            is_missing_stock: item.is_missing_stock,
            count: item.count,
            business_price: item.product.business_price,
          }));
        }
      );
  },
});

export const getOrderProducts = (state: RootState) => state.order.products;
export const getOrderSpecialOffers = (state: RootState) =>
  state.order.order_special_items;
export const getOrderReturnProducts = (state: RootState) =>
  state.order.return_products;
export const getSelfServiceData = (state: RootState) =>
  state.order.selfServiceData;

export const {
  addOrderProduct,
  saveProducts,
  increaseProductCount,
  decreaseProductCount,
  changeProductCount,
  saveSelfServiceData,
  resetSelfServiceData,
  deleteOrderProduct,
  resetProducts,
  increaseReturnProductCount,
  decreaseReturnProductCount,
  addReturnProduct,
  changeProductReason,
  deleteReturnProduct,
  changeReturnProductCount,
  toggleAmountReason,
  toggleDamagedReason,
  toggleMissingInStock,
  addSpecialOffer,
} = ordersSlice.actions;

export default ordersSlice.reducer;
