import React from 'react';
import { paths } from './paths';
import {
  ClientIcon,
  DashboardIcon,
  DatabaseIcon,
  MailIcon,
  ToDoListIcon,
  UsersIcon,
  FeedbackIcon,
  AgentsIcon,
  QuestionsIcon,
  SpecialsIcon,
  ProductsIcon,
  ReturnIcon,
  OrdersByDriversIcon,
  TasksDriverIcon,
  AreasIcon,
  DriversIcon,
  ConcentrationIcon,
} from '../theme/components/Icons';
import { IDefaultRoutes, IRoutesByRole } from '../types/types';

const INClientInReport = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/INClient/INClientReports/INClientInReport/INClientInReport'
    )
);
const NewOrder = React.lazy(
  () => import('../Components/Pages/Main/NewOrder1/NewOrder1')
);
const NewOrderNeedToPay = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Orders/OrdersShoppingCart/NewOrder/NewOrderNeedToPay/NewOrderNeedToPay'
    )
);
const OrderInProgress = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Orders/OrdersShoppingCart/OrderInProgress/OrderInProgress'
    )
);
const NewOrderAddPrivious = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Orders/OrdersHistory/NewOrderAddPrivious/NewOrderAddPrivious'
    )
);
const OrderSentView = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Orders/OrdersSoppingList/OrderSentView/OrderSentView'
    )
);
const OrderCenceledView = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Orders/OrdersSoppingList/OrderCenceledView/OrderCenceledView'
    )
);
const NewOrderView = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Orders/OrdersHistory/NewOrderView/NewOrderView'
    )
);
const OrdersReturns = React.lazy(
  () => import('../Components/Pages/Main/Orders/OrdersReturns/OrdersReturns')
);
const OrdersReturnsView = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Orders/OrdersReturns/OrdersReturnView/OrdersReturnsView'
    )
);
const PrioritizeShipments = React.lazy(
  () =>
    import('../Components/Pages/Main/PrioritizeShipments/PrioritizeShipments')
);
const PrioritizeShipmentsDeliveryPriority = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/PrioritizeShipments/PrioritizeShipmentsDeliveryPriority/PrioritizeShipmentsDeliveryPriority'
    )
);
const TifulDriverTasks = React.lazy(
  () => import('../Components/Pages/Main/TifulDriverTasks/TifulDriverTasks')
);
const DivisionLines = React.lazy(
  () => import('../Components/Pages/Main/DivisionLines/DivisionLines')
);
const Information = React.lazy(
  () => import('../Components/Pages/Main/Information/Information')
);
const ConcentrationReports = React.lazy(
  () =>
    import('../Components/Pages/Main/ConcentrationReports/ConcentrationReports')
);
const ConcentrationReportsView = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/ConcentrationReports/ConcentrationReportsView/ConcentrationReportsView'
    )
);
const OrderDone = React.lazy(
  () => import('../Components/Pages/Main/Orders/OrderDone/OrderDone')
);
const Clients = React.lazy(
  () => import('../Components/Pages/Main/Clients/Clients')
);
const Dashboard = React.lazy(
  () => import('../Components/Pages/Main/Dashboard/Dashboard')
);
const INClient = React.lazy(
  () => import('../Components/Pages/Main/INClient/INClient')
);
const NewClient = React.lazy(
  () => import('../Components/Pages/Main/Clients/NewClient/NewClient')
);
const ReportsAgentGoals = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Reports/ReportsAgentGoals/ReportsAgentGoals'
    )
);
// const Main = React.lazy(() => import('../Components/Pages/Main/Main'));
const Orders = React.lazy(
  () => import('../Components/Pages/Main/Orders/Orders')
);
const Reports = React.lazy(
  () => import('../Components/Pages/Main/Reports/Reports')
);
const SendNotification = React.lazy(
  () => import('../Components/Pages/Main/SendNotification/SendNotification')
);
const Tasks = React.lazy(() => import('../Components/Pages/Main/Tasks/Tasks'));
const ReportsClientActivity = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Reports/ReportsClientActivity/ReportsClientActivity'
    )
);
const ReportsClientPricing = React.lazy(
  () =>
    import(
      '../Components/Pages/Main/Reports/ReportsClientPricing/ReportsClientPricing'
    )
);
const Feedbacks = React.lazy(
  () => import('../Components/Pages/Main/Feedbacks/Feedbacks')
);
const INFeedback = React.lazy(
  () => import('../Components/Pages/Main/INFeedback/INFeedback')
);
const Questions = React.lazy(
  () => import('../Components/Pages/Main/Questions/Questions')
);
const NewQuestion = React.lazy(
  () => import('../Components/Pages/Main/NewQuestion/NewQuestion')
);
const QuestionReport = React.lazy(
  () => import('../Components/Pages/Main/QuestionReport/QuestionReport')
);
const Agents = React.lazy(
  () => import('../Components/Pages/Main/Agents/Agents')
);
const NewAgent = React.lazy(
  () => import('../Components/Pages/Main/NewAgent/NewAgent')
);
const INAgent = React.lazy(
  () => import('../Components/Pages/Main/INAgent/INAgent')
);
const DriverTasks = React.lazy(
  () => import('../Components/Pages/Main/DriverTasks/DriverTasks')
);
const DriverTasksDone = React.lazy(
  () => import('../Components/Pages/Main/DriverTasksDone/DriverTasksDone')
);
const INDriverTask = React.lazy(
  () => import('../Components/Pages/Main/INDriverTask/INDriverTask')
);
const DriverNotifications = React.lazy(
  () =>
    import('../Components/Pages/Main/DriverNotifications/DriverNotifications')
);
const Deals = React.lazy(() => import('../Components/Pages/Main/Deals/Deals'));
const TaskNoamMain = React.lazy(
  () => import('../Components/Pages/Main/Tasks/TasksNoamMain/TaskNoamMain')
);
const AddDeal = React.lazy(
  () => import('../Components/Pages/Main/AddDeal/AddDeal')
);
const Products = React.lazy(
  () => import('../Components/Pages/Main/Products/Products')
);
const ManagerReports = React.lazy(
  () => import('../Components/Pages/Main/ManagerReports/ManagerReports')
);
const ManagerDailySales = React.lazy(
  () => import('../Components/Pages/Main/ManagerDailySales/ManagerDailySales')
);
const ReportsGiuls = React.lazy(
  () => import('../Components/Pages/Main/ReportsGiuls/ReportsGiuls')
);
const Login = React.lazy(() => import('../Components/Pages/Login/Login'));

export const roles = {
  NOAM_AGENT: 'noam',
  AGENT: 'agent',
  MANAGER: 'manager',
  DRIVER: 'driver',
  TIFUL: 'tiful',
  OFFICE_ORDER: 'office_order',
};

export const defaultRoutesByRole: IDefaultRoutes = {
  noam: `noam${paths.dashboard}`,
  agent: `/agent${paths.dashboard}`,
  driver: `/driver${paths.tasks}`,
  manager: `/manager${paths.dashboard}`,
  tiful: `/tiful${paths.dashboard}`,
  office_order: `/office_order${paths.ordersCart}`,
  none: `/login`,
};

export const routesByRole: IRoutesByRole = {
  noam: [
    // -------------------------------------------- noam
    {
      element: <Dashboard />,
      route: `/noam${paths.dashboard}`,
      pageTitle: 'דשבורד',
      renderIcon: (currentPath) => (
        <DashboardIcon
          isActive={currentPath.includes(`/noam${paths.dashboard}`)}
        />
      ),
    },

    {
      element: <Orders />,
      route: `/noam${paths.ordersCart}`,
      pageTitle: 'הזמנות',
      pathKey: 'orders',
      counterKey: 'new_orders',
      renderIcon: (currentPath, hasCounter) => (
        <ClientIcon
          hasCounter={hasCounter}
          isActive={
            currentPath.includes(`/noam${paths.ordersCart}`) ||
            currentPath.includes(`/noam${paths.ordersNew}`) ||
            currentPath.includes(`/noam${paths.ordersHistory}`) ||
            currentPath.includes(`/noam${paths.ordersShoppingList}`)
          }
        />
      ),
    },

    {
      element: <Orders />,
      route: `/noam${paths.ordersHistory}`,
    },
    {
      element: <Orders />,
      route: `/noam${paths.ordersShoppingList}`,
    },
    {
      element: <NewOrder />,
      route: `/noam${paths.ordersNew}/:status`,
    },

    {
      element: <Clients />,
      route: `/noam${paths.client}`,
      counterKey: 'new_clients',
      pageTitle: 'לקוחות',
      renderIcon: (currentPath, hasCounter) => (
        <UsersIcon
          isActive={currentPath.includes(`/noam${paths.client}`)}
          hasCounter={hasCounter}
        />
      ),
    },

    {
      element: <INClient />,
      route: `/noam${paths.inClientHome}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/noam${paths.inClient}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/noam${paths.inClientReceipt}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/noam${paths.inClientFlag}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/noam${paths.inClientPriceList}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/noam${paths.inClientUser}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/noam${paths.inClientSettings}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/noam${paths.inClientArchive}/:client_id`,
    },
    {
      element: <NewClient />,
      route: `/noam${paths.newClient}`,
    },
    {
      element: <INClientInReport />,
      route: `/noam${paths.inClientInReport}/:id`,
    },

    {
      element: <ReportsClientPricing />,
      route: `/noam${paths.reportsClientPricing}`,
    },

    {
      element: <Agents />,
      route: `/noam${paths.agents}`,
      pageTitle: 'סוכנים',
      renderIcon: (currentPath) => (
        <AgentsIcon isActive={currentPath.includes(`/noam${paths.agents}`)} />
      ),
    },

    {
      element: <NewAgent />,
      route: `/noam${paths.newAgent}`,
    },

    {
      element: <INAgent />,
      route: `/noam${paths.inAgentView}/:agent_id/:tab`,
    },

    {
      element: <ManagerReports />,
      route: `/noam${paths.reports}`,
      pageTitle: 'דוחות',
      renderIcon: (currentPath) => (
        <DatabaseIcon
          isActive={currentPath.includes(`/noam${paths.reports}`)}
        />
      ),
    },

    {
      element: <TaskNoamMain />,
      counterKey: 'new_answers_from_agents',
      route: `/noam${paths.tasks}`,
      pageTitle: 'משימות סוכנים',
      renderIcon: (currentPath, hasCounter) => (
        <ToDoListIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/noam${paths.tasks}`)}
        />
      ),
    },

    {
      element: <Tasks />,
      route: `/noam${paths.tasksMore}/:agent_id`,
    },

    {
      element: <ReportsClientActivity />,
      route: `/noam${paths.reportsClientActivity}`,
    },

    {
      element: <ReportsClientPricing />,
      route: `/noam${paths.reportsClientPricing}`,
    },

    {
      element: <ReportsAgentGoals />,
      route: `/noam${paths.reportsAgentGoals}`,
    },

    {
      element: <Feedbacks />,
      counterKey: 'new_messages_in_reports',
      route: `/noam${paths.feedbacks}`,
      pageTitle: 'מָשׁוֹב',
      renderIcon: (currentPath, hasCounter) => (
        <FeedbackIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/noam${paths.feedbacks}`)}
        />
      ),
    },

    {
      element: <INFeedback />,
      route: `/noam${paths.inFeedback}/:report_id`,
    },

    {
      element: <Questions />,
      route: `/noam${paths.questionnaires}`,
      pageTitle: 'שאלונים',
      renderIcon: (currentPath) => (
        <QuestionsIcon
          isActive={currentPath.includes(`/noam${paths.questionnaires}`)}
        />
      ),
    },

    {
      element: <QuestionReport />,
      route: `/noam${paths.questionReport}/:id`,
    },

    {
      element: <NewQuestion />,
      route: `/noam${paths.newQuestion}`,
    },

    {
      element: <Deals />,
      route: `/noam${paths.deals}`,
      pageTitle: 'מבצעים',
      renderIcon: (currentPath) => (
        <SpecialsIcon isActive={currentPath.includes(`/noam${paths.deals}`)} />
      ),
    },

    {
      element: <AddDeal />,
      route: `/noam${paths.addDeal}`,
    },

    {
      element: <Products />,
      route: `/noam${paths.products}`,
      pageTitle: 'מוצרים',
      renderIcon: (currentPath) => (
        <ProductsIcon
          isActive={currentPath.includes(`/noam${paths.products}`)}
        />
      ),
    },

    {
      element: <SendNotification />,
      route: `/noam${paths.sendNotification}`,
      pageTitle: 'התראות',
      renderIcon: (currentPath) => (
        <MailIcon
          isActive={currentPath.includes(`/noam${paths.sendNotification}`)}
        />
      ),
    },
  ],
  agent: [
    // -------------------------------------------- agent
    {
      element: <Dashboard />,
      route: `/agent${paths.dashboard}`,
      pageTitle: 'דשבורד',
      renderIcon: (currentPath) => (
        <DashboardIcon
          isActive={currentPath.includes(`/agent${paths.dashboard}`)}
        />
      ),
    },

    {
      element: <Orders />,
      route: `/agent${paths.ordersCart}`,
      counterKey: 'new_orders',
      pageTitle: 'הזמנות',
      pathKey: 'orders',
      renderIcon: (currentPath, hasCounter) => (
        <ClientIcon
          hasCounter={hasCounter}
          isActive={
            currentPath.includes(`/agent${paths.ordersCart}`) ||
            currentPath.includes(`/agent${paths.ordersNew}`) ||
            currentPath.includes(`/agent${paths.ordersHistory}`) ||
            currentPath.includes(`/agent${paths.ordersShoppingList}`)
          }
        />
      ),
    },
    {
      element: <Clients />,
      route: `/agent${paths.client}`,
      counterKey: 'new_clients',
      pageTitle: 'לקוחות',
      renderIcon: (currentPath, hasCounter) => (
        <UsersIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/agent${paths.client}`)}
        />
      ),
    },

    {
      element: <Reports />,
      route: `/agent${paths.reports}`,
      pageTitle: 'דוחות',
      renderIcon: (currentPath) => (
        <DatabaseIcon
          isActive={currentPath.includes(`/agent${paths.reports}`)}
        />
      ),
    },
    {
      element: <Tasks />,
      route: `/agent${paths.tasks}`,
      pageTitle: 'משימות',
      renderIcon: (currentPath, hasCounter) => (
        <ToDoListIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/agent${paths.tasks}`)}
        />
      ),
    },
    {
      element: <SendNotification />,
      route: `/agent${paths.sendNotification}`,
      pageTitle: 'התראות',
      renderIcon: (currentPath) => (
        <MailIcon
          isActive={currentPath.includes(`/agent${paths.sendNotification}`)}
        />
      ),
    },
    {
      element: <ReportsClientActivity />,
      route: `/agent${paths.reportsClientActivity}`,
    },
    {
      element: <Orders />,
      route: `/agent${paths.ordersHistory}`,
    },
    {
      element: <Orders />,
      route: `/agent${paths.ordersShoppingList}`,
    },
    {
      element: <NewOrder />,
      route: `/agent${paths.ordersNew}/:status`,
    },
    {
      element: <INClient />,
      route: `/agent${paths.inClientHome}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/agent${paths.inClient}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/agent${paths.inClientReceipt}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/agent${paths.inClientFlag}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/agent${paths.inClientPriceList}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/agent${paths.inClientUser}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/agent${paths.inClientSettings}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/agent${paths.inClientArchive}/:client_id`,
    },
    {
      element: <NewClient />,
      route: `/agent${paths.newClient}`,
    },
    {
      element: <INClientInReport />,
      route: `/agent${paths.inClientInReport}/:id`,
    },
    {
      element: <ReportsClientPricing />,
      route: `/agent${paths.reportsClientPricing}`,
    },
    {
      element: <ReportsAgentGoals />,
      route: `/agent${paths.reportsAgentGoals}`,
    },
    {
      element: <Tasks />,
      route: `/agent${paths.tasks}`,
      pageTitle: 'משימות סוכנים',
    },
  ],
  driver: [
    // -------------------------------------------- driver
    {
      element: <DriverTasks />,
      route: `/driver${paths.tasks}`,
      pageTitle: 'קו חלוקה',
      renderIcon: (currentPath, hasCounter) => (
        <ToDoListIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/driver${paths.tasks}`)}
        />
      ),
    },

    {
      element: <DriverTasksDone />,
      route: `/driver${paths.ordersCart}`,
      pageTitle: 'הזמנות שנמסרו',
      renderIcon: (currentPath, hasCounter) => (
        <ClientIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/driver${paths.ordersCart}`)}
        />
      ),
    },

    {
      element: <INDriverTask />,
      route: `/driver${paths.inDriverTask}/:driver_id`,
    },

    {
      element: <INDriverTask />,
      route: `/driver${paths.inDriverTaskDone}/:driver_id`,
    },

    {
      element: <DriverNotifications />,
      route: `/driver${paths.notifications}`,
    },
  ],
  manager: [
    // -------------------------------------------- manager
    {
      element: <Dashboard />,
      route: `/manager${paths.dashboard}`,
      pageTitle: 'דשבורד',
      renderIcon: (currentPath) => (
        <DashboardIcon
          isActive={currentPath.includes(`/manager${paths.dashboard}`)}
        />
      ),
    },

    {
      element: <Orders />,
      route: `/manager${paths.ordersCart}`,
      pageTitle: 'הזמנות',
      pathKey: 'orders',
      counterKey: 'new_orders',
      renderIcon: (currentPath, hasCounter) => (
        <ClientIcon
          hasCounter={hasCounter}
          isActive={
            currentPath.includes(`/manager${paths.ordersCart}`) ||
            currentPath.includes(`/manager${paths.ordersNew}`) ||
            currentPath.includes(`/manager${paths.ordersHistory}`) ||
            currentPath.includes(`/manager${paths.ordersShoppingList}`)
          }
        />
      ),
    },

    {
      element: <Clients />,
      route: `/manager${paths.client}`,
      counterKey: 'new_clients',
      pageTitle: 'לקוחות',
      renderIcon: (currentPath, hasCounter) => (
        <UsersIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/manager${paths.client}`)}
        />
      ),
    },

    {
      element: <Agents />,
      route: `/manager${paths.agents}`,
      pageTitle: 'סוכנים',
      renderIcon: (currentPath) => (
        <AgentsIcon
          isActive={currentPath.includes(`/manager${paths.agents}`)}
        />
      ),
    },

    {
      element: <ManagerReports />,
      route: `/manager${paths.reports}`,
      pageTitle: 'דוחות',
      renderIcon: (currentPath) => (
        <DatabaseIcon
          isActive={currentPath.includes(`/manager${paths.reports}`)}
        />
      ),
    },

    {
      element: <TaskNoamMain />,
      route: `/manager${paths.tasks}`,
      counterKey: 'new_answers_from_agents',
      pageTitle: 'משימות סוכנים',
      renderIcon: (currentPath, hasCounter) => (
        <ToDoListIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/manager${paths.tasks}`)}
        />
      ),
    },

    {
      element: <Feedbacks />,
      counterKey: 'new_messages_in_reports',
      route: `/manager${paths.feedbacks}`,
      pageTitle: 'מָשׁוֹב',
      renderIcon: (currentPath, hasCounter) => (
        <FeedbackIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/manager${paths.feedbacks}`)}
        />
      ),
    },

    {
      element: <Questions />,
      route: `/manager${paths.questionnaires}`,
      pageTitle: 'שאלונים',
      renderIcon: (currentPath) => (
        <QuestionsIcon
          isActive={currentPath.includes(`/manager${paths.questionnaires}`)}
        />
      ),
    },

    {
      element: <SendNotification />,
      route: `/manager${paths.sendNotification}`,
      pageTitle: 'התראות',
      renderIcon: (currentPath) => (
        <MailIcon
          isActive={currentPath.includes(`/manager${paths.sendNotification}`)}
        />
      ),
    },
    {
      element: <Products />,
      route: `/manager${paths.products}`,
      pageTitle: 'מוצרים',
      renderIcon: (currentPath) => (
        <ProductsIcon
          isActive={currentPath.includes(`/manager${paths.products}`)}
        />
      ),
    },
    {
      element: <Deals />,
      route: `/manager${paths.deals}`,
      pageTitle: 'מבצעים',
      renderIcon: (currentPath) => (
        <SpecialsIcon
          isActive={currentPath.includes(`/manager${paths.deals}`)}
        />
      ),
    },
    {
      element: <INClientInReport />,
      route: `/manager${paths.inClientInReport}/:id`,
    },
    {
      element: <AddDeal />,
      route: `/manager${paths.addDeal}`,
    },

    {
      element: <QuestionReport />,
      route: `/manager${paths.questionReport}/:id`,
    },

    {
      element: <NewQuestion />,
      route: `/manager${paths.newQuestion}`,
    },

    {
      element: <INFeedback />,
      route: `/manager${paths.inFeedback}/:report_id`,
    },

    {
      element: <Tasks />,
      route: `/manager${paths.tasksMore}/:agent_id`,
    },

    {
      element: <NewAgent />,
      route: `/manager${paths.newAgent}`,
    },

    {
      element: <INAgent />,
      route: `/manager${paths.inAgentView}/:agent_id/:tab`,
    },

    {
      element: <Orders />,
      route: `/manager${paths.ordersHistory}`,
    },
    {
      element: <Orders />,
      route: `/manager${paths.ordersShoppingList}`,
    },
    {
      element: <NewOrder />,
      route: `/manager${paths.ordersNew}/:status`,
    },
    {
      element: <NewOrderNeedToPay />,
      route: `/manager${paths.ordersNewNeedToPay}`,
    },
    {
      element: <OrderInProgress />,
      route: `/manager${paths.ordersInProgress}`,
    },
    {
      element: <NewOrderAddPrivious />,
      route: `/manager${paths.ordersAddNewPrivious}`,
    },
    {
      element: <OrderSentView />,
      route: `/manager${paths.ordersSentView}`,
    },
    {
      element: <OrderCenceledView />,
      route: `/manager${paths.ordersCenceledView}`,
    },
    {
      element: <NewOrderView />,
      route: `/manager${paths.ordersNewView}`,
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClientHome}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClient}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClientReceipt}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClientFlag}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClientPriceList}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClientUser}/:client_id`, //
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClientSettings}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClientPaymentStatistics}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/manager${paths.inClientArchive}/:client_id`,
    },
    {
      element: <NewClient />,
      route: `/manager${paths.newClient}`,
    },

    {
      element: <ReportsClientActivity />,
      route: `/manager${paths.reportsClientActivity}`,
    },

    {
      element: <ReportsAgentGoals />,
      route: `/manager${paths.reportsAgentGoals}`,
    },

    {
      element: <ReportsClientPricing />,
      route: `/manager${paths.reportsClientPricing}`,
    },

    {
      element: <ReportsClientPricing salesMode={true} />,
      route: `/manager${paths.reportsClientSales}`,
    },

    {
      element: <ManagerDailySales />,
      route: `/manager${paths.reportsDailySales}`,
    },

    {
      element: <ReportsGiuls />,
      route: `/manager${paths.reportsGiuls}`,
    },
  ],
  tiful: [
    // -------------------------------------------- tiful

    {
      element: <Dashboard />,
      route: `/tiful${paths.dashboard}`,
      pageTitle: 'דשבורד',
      renderIcon: (currentPath) => (
        <DashboardIcon
          isActive={currentPath.includes(`/tiful${paths.dashboard}`)}
        />
      ),
    },

    {
      element: <Orders />,
      route: `/tiful${paths.ordersCart}`,
      pageTitle: 'הזמנות',
      pathKey: 'orders',
      counterKey: 'new_orders',
      renderIcon: (currentPath, hasCounter) => (
        <ClientIcon
          hasCounter={hasCounter}
          isActive={
            currentPath.includes(`/tiful${paths.ordersCart}`) ||
            currentPath.includes(`/tiful${paths.ordersNew}`) ||
            currentPath.includes(`/tiful${paths.ordersHistory}`) ||
            currentPath.includes(`/tiful${paths.ordersShoppingList}`)
          }
        />
      ),
    },

    {
      element: <OrdersReturns />,
      route: `/tiful${paths.returns}`,
      pageTitle: 'החזרות',
      renderIcon: (currentPath) => (
        <ReturnIcon isActive={currentPath.includes(`/tiful${paths.returns}`)} />
      ),
    },

    {
      element: <Clients />,
      route: `/tiful${paths.customers}`,
      pageTitle: 'לקוחות',
      counterKey: 'new_clients',
      renderIcon: (currentPath, hasCounter) => (
        <UsersIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/tiful${paths.customers}`)}
        />
      ),
    },

    {
      element: <PrioritizeShipments />,
      route: `/tiful${paths.prioritizeShipments}`,
      pageTitle: 'תיעדוף משלוחים',
      renderIcon: (currentPath) => (
        <OrdersByDriversIcon
          isActive={currentPath.includes(`/tiful${paths.prioritizeShipments}`)}
        />
      ),
    },
    {
      element: <TifulDriverTasks />,
      route: `/tiful${paths.driverTasks}`,
      pageTitle: 'משימות נהג',
      renderIcon: (currentPath) => (
        <TasksDriverIcon
          isActive={currentPath.includes(`/tiful${paths.driverTasks}`)}
        />
      ),
    },
    {
      element: <DivisionLines />,
      route: `/tiful${paths.divisionLines}`,
      pageTitle: 'קווי חלוקה',
      renderIcon: (currentPath) => (
        <AreasIcon
          isActive={currentPath.includes(`/tiful${paths.divisionLines}`)}
        />
      ),
    },
    {
      element: <Information />,
      route: `/tiful/information/driver`,
      pageTitle: 'מֵידָע',
      pathKey: 'information',
      renderIcon: (currentPath) => (
        <DriversIcon isActive={currentPath.includes('information')} />
      ),
    },
    {
      element: <ConcentrationReports />,
      route: `/tiful${paths.concentrationReports}`,
      pageTitle: 'דוחות ריכוז',
      renderIcon: (currentPath) => (
        <ConcentrationIcon
          isActive={currentPath.includes(`/tiful${paths.concentrationReports}`)}
        />
      ),
    },
    {
      element: <ConcentrationReportsView />,
      route: `/tiful${paths.concentrationReportsView}`,
    },
    {
      element: <OrderDone />,
      route: `/tiful${paths.orderDone}`,
    },
    {
      element: <ReportsClientActivity />,
      route: `/tiful${paths.reportsClientActivity}`,
    },
    {
      element: <Information />,
      route: `/tiful${paths.informationCargoTruck}`,
    },
    {
      element: <Information />,
      route: `/tiful${paths.information}`,
    },
    {
      element: <Information />,
      route: `/tiful${paths.informationRoute}`,
    },
    {
      element: <Orders />,
      route: `/tiful${paths.ordersHistory}`,
    },
    {
      element: <Orders />,
      route: `/tiful${paths.ordersShoppingList}`,
    },
    {
      element: <NewOrder />,
      route: `/tiful${paths.ordersNew}/:status`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.customersInClient}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.customersInClientFlag}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.customersInClientUser}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.customersInClientSettings}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.inClientHome}/:client_id`,
    },
    { element: <INClient />, route: `/tiful${paths.inClient}/:client_id` },
    {
      element: <INClient />,
      route: `/tiful${paths.inClientReceipt}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.inClientFlag}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.inClientPriceList}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.inClientUser}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.inClientSettings}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/tiful${paths.inClientArchive}/:client_id`,
    },
    {
      element: <NewClient />,
      route: `/tiful${paths.newClient}`,
    },
    {
      element: <ReportsClientPricing />,
      route: `/tiful${paths.reportsClientPricing}`,
    },
    {
      element: <ReportsAgentGoals />,
      route: `/tiful${paths.reportsAgentGoals}`,
    },
    {
      element: <Tasks />,
      route: `/tiful${paths.tasks}`,
      pageTitle: 'משימות סוכנים',
    },
    {
      element: <OrdersReturnsView />,
      route: `/tiful${paths.returnsView}/:return_id`,
    },
    {
      element: <PrioritizeShipmentsDeliveryPriority />,
      route: `/tiful${paths.prioritizeShipmentsDeliveryPriority}/:id`,
    },
  ],
  office_order: [
    // -------------------------------------------- office_order
    {
      element: <Orders />,
      route: `/office_order${paths.ordersCart}`,
      pageTitle: 'הזמנות',
      pathKey: 'orders',
      counterKey: 'new_orders',
      renderIcon: (currentPath, hasCounter) => (
        <ClientIcon
          hasCounter={hasCounter}
          isActive={
            currentPath.includes(`/office_order${paths.ordersCart}`) ||
            currentPath.includes(`/office_order${paths.ordersNew}`) ||
            currentPath.includes(`/office_order${paths.ordersHistory}`) ||
            currentPath.includes(`/office_order${paths.ordersShoppingList}`)
          }
        />
      ),
    },

    {
      element: <Clients />,
      route: `/office_order${paths.client}`,
      pageTitle: 'לקוחות',
      counterKey: 'new_clients',
      renderIcon: (currentPath, hasCounter) => (
        <UsersIcon
          hasCounter={hasCounter}
          isActive={currentPath.includes(`/office_order${paths.client}`)}
        />
      ),
    },
    {
      element: <INClientInReport />,
      route: `/office_order${paths.inClientInReport}/:id`,
    },
    {
      element: <Orders />,
      route: `/office_order${paths.ordersHistory}`,
    },
    {
      element: <Orders />,
      route: `/office_order${paths.ordersShoppingList}`,
    },
    {
      element: <NewOrder />,
      route: `/office_order${paths.ordersNew}/:status`,
    },
    {
      element: <INClient />,
      route: `/office_order${paths.inClientHome}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/office_order${paths.inClient}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/office_order${paths.inClientReceipt}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/office_order${paths.inClientFlag}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/office_order${paths.inClientPriceList}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/office_order${paths.inClientUser}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/office_order${paths.inClientSettings}/:client_id`,
    },
    {
      element: <INClient />,
      route: `/office_order${paths.inClientArchive}/:client_id`,
    },
    {
      element: <NewClient />,
      route: `/office_order${paths.newClient}`,
    },
  ],
  none: [
    // -------------------------------------------- office_order
    {
      element: <Login />,
      route: `/login`,
    },
  ],
};
