import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDateFormat, getTimeFormat } from '../../../helpers/common';
import { useAppSelector } from '../../../hooks/redux';
import { selectNotificationListener } from '../../../redux/userSlice';
import { notificationsApi } from '../../../services/notification';

const useNotificationPopup = () => {
  const [page, setPage] = useState<number>(1);
  const userRole = useAppSelector((state) => state.user.info.role);
  const updateNeaded = useAppSelector(selectNotificationListener);
  const {
    data: notificationsData,
    isLoading,
    refetch,
  } = notificationsApi.useGetNotificationsQuery(
    { page, search: '' },
    { skip: !userRole }
  );

  const [readAllNotifications, { isLoading: isLoadingReading }] =
    notificationsApi.useDeleteNotificationMutation();

  useEffect(() => {
    refetch();
  }, [updateNeaded]);

  const onPageChange = useCallback((newPage: number) => setPage(newPage), []);

  const notifications = useMemo(() => {
    return notificationsData
      ? notificationsData.data.map((notification) => ({
          ...notification,
          created_at: getDateFormat(Number(notification.created_at)),
          // time: getTimeFormat(Number(notification.created_at)),
          time: moment(Number(notification.created_at) * 1000).format('HH:mm'),
        }))
      : [];
  }, [notificationsData]);

  return {
    notifications,
    refetch,
    onPageChange,
    isLoading,
    total: notificationsData?.total || 0,
    currentPage: notificationsData?.current_page || 1,
  };
};

export default useNotificationPopup;
