import {
  Center,
  FormControl,
  FormLabel,
  FormLabelProps,
  InputProps,
  Text,
  Wrap,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { emailPattern } from '../../../../constants/constants';
import { setUserEmail } from '../../../../redux/userSlice';
import { userApi } from '../../../../services/user';
import IButton from '../../../Common/Button/Button';
import { Input } from '../../../Common/Input/Input';

const LoginSecondForm = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = userApi.useForgotPasswordMutation();

  const handleClick = () => {
    forgotPassword({ email })
      .unwrap()
      .then(() => {
        dispatch(setUserEmail(email));
        // navigate('/login/reset-password');
      });
  };

  return (
    <Center mt="45px">
      <Center maxW="380px" flexDir="column">
        <Text fontWeight="bold" fontSize="24px" color="brand.yellow">
          איפוס סיסמה
        </Text>
        <Text fontSize="18px" color="brand.yellow" textAlign="center" mt="15px">
          קבלת מייל להמשל התהליך הזינו את כתובת המייל המקושרת לחשבונכם לצורך
        </Text>
        <FormControl
          maxW="345px"
          mt="15px"
          isInvalid={!!email && !emailPattern.test(email)}
        >
          <FormLabel {...labelStyle}>כתובת מייל</FormLabel>
          <Input
            {...inputStyle}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            id="email"
            type="email"
            border="1px solid brand.white"
          />
        </FormControl>
        <Wrap mt="53px" w="full" justify="center">
          <IButton
            text=" כניסה"
            onClick={handleClick}
            isDisabled={isLoading || !email}
          />
        </Wrap>
      </Center>
    </Center>
  );
};

const labelStyle: FormLabelProps = {
  color: 'brand.yellow',
  fontSize: '16px',
  htmlFor: 'email',
  m: '0 20px 15px 0',
};

const inputStyle: InputProps = {
  variant: 'first',
  fontSize: '18px',
  color: 'brand.white',
};

export default LoginSecondForm;
