import { RootState } from "./../redux/store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ILogin, IUser } from "../models/IUser/IUser";
import { ITifulDriverTasksResponse } from "../models/ITask/ITask";

export const tifulTasksApi = createApi({
  reducerPath: "tifulTasksApi",
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: "https://balkan-api.dev.bsx-group.com",
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getDriverTasks: build.query<
      ITifulDriverTasksResponse,
      {
        search: string;
        page: number;
        line_id: string;
        driver_id: string;
        from_date: string;
        to_date: string;
        status_id: string;
        business_id: string;
      }
    >({
      transformResponse: (response: { data: ITifulDriverTasksResponse }) =>
        response.data,
      query: ({
        search,
        page,
        line_id,
        driver_id,
        from_date,
        to_date,
        status_id,
        business_id,
      }) => ({
        url: "/api/tiful/tasks",
        method: "GET",
        params: {
          search,
          page,
          line_id,
          driver_id,
          from_date,
          to_date,
          status_id,
          business_id,
        },
      }),
    }),
  }),
});
