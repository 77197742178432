import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Components/Pages/Login/Login';
import './theme/variables.css';
import Main from './Components/Pages/Main/Main';

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/reset-email" element={<Login email />} />
        <Route path="/password/reset/:token" element={<Login password />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </Router>
  );
};

export default App;
