import { IProduct } from './../models/IProduct/IProduct';
import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  IProductCategory,
  IProductResponse,
} from '../models/IProduct/IProduct';
import { ClientCreateThirdStep } from '../models/IClient/IClient';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  tagTypes: ['Product'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getProductsCategories: build.query<IProductCategory[], string>({
      transformResponse: (response: { data: IProductCategory[] }) =>
        response.data,
      query: () => ({
        url: '/api/catalog/categories',
        method: 'GET',
      }),
    }),
    getProducts: build.query<
      IProductResponse,
      { page: number; query: string; category?: string }
    >({
      transformResponse: (response: { data: IProductResponse }) =>
        response.data,
      query: ({ page, query, category }) => ({
        url: '/api/catalog/products',
        method: 'GET',
        params: {
          page,
          search: query,
          category_id: category,
        },
      }),
      providesTags: () => ['Product'],
    }),
    getProductsShortList: build.query<IProduct[], any>({
      transformResponse: (response: { data: IProduct[] }) => response.data,
      query: () => ({
        url: '/api/catalog/products/short-list',
        method: 'GET',
      }),
      providesTags: () => ['Product'],
    }),
    createProduct: build.mutation<IProduct, any>({
      transformResponse: (response: { data: IProduct }) => response.data,
      query: (newProduct) => {
        return {
          url: '/api/catalog/products',
          method: 'POST',
          body: newProduct,
        };
      },
      invalidatesTags: ['Product'],
    }),
    updateProduct: build.mutation<IProduct, { product: any; id: any }>({
      transformResponse: (response: { data: IProduct }) => response.data,
      query: (data) => {
        return {
          url: `/api/catalog/products/${data.id}`,
          method: 'POST',
          body: data.product,
        };
      },
      invalidatesTags: ['Product'],
    }),
    updateProductsPricing: build.mutation<
      any,
      { clientId: string; data: ClientCreateThirdStep }
    >({
      transformResponse: (response: { data: IProduct }) => response.data,
      query: ({ clientId, data }) => {
        return {
          url: `api/clients/${clientId}/pricing`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Product'],
    }),
    deleteProduct: build.mutation<IProduct, number>({
      query: (productId) => {
        return {
          url: `/api/catalog/products/${productId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Product'],
    }),
    // exportProduсts: build.query<
    //   any,
    //   { search: string; ids: number[]; category_id: string }
    // >({
    //   query: ({ search, ids, category_id }) => {
    //     return {
    //       url: `/api/catalog/products/export`,
    //       method: "GET",
    //       params: {
    //         search,
    //         ids,
    //         category_id,
    //       },
    //     };
    //   },
    // }),
  }),
});
