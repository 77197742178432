import { Center, Image } from "@chakra-ui/react";
import portret from "../../../Images/PNG/Portret-small.png";

const AppFallback = () => {
  return (
    <Center h="100%" w="100%">
      <Image opacity="0.2" w="50px" src={portret} alt="app-fallback" />
    </Center>
  );
};

export default AppFallback;
