import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  IDivisionLines,
  IDivisionLinesRes,
  ILine,
  ILineResponse,
  IUpdateDivisionLines,
} from '../models/ILine/ILine';

export const linesApi = createApi({
  reducerPath: 'linesApi',
  tagTypes: ['Line'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getLines: build.query<ILineResponse, string>({
      transformResponse: (response: { data: ILineResponse }) => response.data,
      query: (search) => ({
        url: '/api/lines',
        method: 'GET',
        params: {
          search,
        },
      }),
    }),
    createLine: build.mutation<ILine, ILine>({
      query: (newLine) => ({
        url: '/api/lines',
        method: 'POST',
        body: newLine,
      }),
    }),
    updateLine: build.mutation<ILine, ILine>({
      query: (updatedLine) => {
        return {
          url: `/api/lines/${updatedLine.id}`,
          method: 'POST',
          body: { name: updatedLine.name },
        };
      },
    }),
    deleteLine: build.mutation<ILine, number>({
      query: (id) => {
        return {
          url: `/api/lines/${id}`,
          method: 'DELETE',
        };
      },
    }),
    deliveryDays: build.query<any, any>({
      transformResponse: (response: { data: any }) => response.data,
      query: () => {
        return {
          url: '/api/delivery-days',
          method: 'GET',
        };
      },
    }),
    updateDeliveryDays: build.mutation<any, any>({
      transformResponse: (response: { data: any }) => response.data,
      query: () => {
        return {
          url: '/api/delivery-day/update',
          method: 'POST',
        };
      },
    }),
    divisionLines: build.query<
      IDivisionLines[],
      { search: string; line_id: string; days: number[] | undefined }
    >({
      transformResponse: (response: { data: { data: IDivisionLines[] } }) =>
        response.data.data,
      query: ({ search, line_id, days }) => {
        return {
          url: '/api/division-lines',
          method: 'GET',
          params: { search, line_id, days: JSON.stringify(days) },
        };
      },
    }),
    editingLines: build.query<
      IDivisionLinesRes,
      {
        search: string;
        area_id: string;
        page: number;
        city_id: string;
        id: string | number;
      }
    >({
      transformResponse: (response: { data: IDivisionLinesRes }) =>
        response.data,
      query: ({ search, area_id, city_id, id, page }) => {
        return {
          url: `/api/division-lines/${id}`,
          method: 'GET',
          params: { search, area_id, city_id, page },
        };
      },
    }),
    updateDivisionLines: build.mutation<
      any,
      { formData: IUpdateDivisionLines; id: string | number }
    >({
      transformResponse: (response: { data: { data: any } }) =>
        response.data.data,
      query: ({ id, formData }) => {
        return {
          url: `/api/division-lines/${id}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});
