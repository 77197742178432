import { IQuestion, IQuestionType } from './../models/IQuestion/IQuestion';
import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  ICreateQuestion,
  IQuestionsResponse,
} from '../models/IQuestion/IQuestion';

export const questionsApi = createApi({
  reducerPath: 'questionsApi',
  tagTypes: ['Question'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getQuestions: build.query<
      IQuestionsResponse,
      { page: number; from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: IQuestionsResponse }) =>
        response.data,
      query: ({ page, from_date, to_date }) => ({
        url: '/api/question/index',
        method: 'GET',
        params: {
          page,
          from_date,
          to_date,
        },
      }),
    }),
    getExactQuestion: build.query<IQuestion, string>({
      transformResponse: (response: { data: IQuestion }) => response.data,
      query: (question_id) => ({
        url: `/api/question/show/${question_id}`,
        method: 'GET',
      }),
    }),
    getQuestionTypes: build.query<IQuestionType[], string>({
      transformResponse: (response: { data: IQuestionType[] }) => response.data,
      query: (question_id) => ({
        url: `/api/question/types`,
        method: 'GET',
      }),
    }),
    createQuestion: build.mutation<any, ICreateQuestion>({
      query: (newQuestion) => ({
        url: '/api/question/create',
        method: 'POST',
        body: newQuestion,
      }),
    }),
    publicateQuestion: build.mutation<any, { questionId: number | string }>({
      query: ({ questionId }) => ({
        url: `/api/question/publication/${questionId}`,
        method: 'POST',
      }),
    }),
    changeStatus: build.mutation<
      any,
      { questionId: number | string; status_id: number }
    >({
      query: ({ questionId, status_id }) => ({
        url: `api/question/change-status/${questionId}`,
        method: 'POST',
        params: {
          status_id,
        },
      }),
    }),
    deleteQuestion: build.mutation<any, { questionId: number }>({
      query: (questionId) => {
        return {
          url: `/api/question/remove`,
          method: 'DELETE',
          body: { id: questionId },
        };
      },
    }),
  }),
});
