import { OrderStatus } from './../constants/constants';
import { IProduct } from '../models/IProduct/IProduct';
import moment from 'moment';
import { ordersStatuses } from '../constants/constants';

export const dayTitles: any = {
  1: 'א׳',
  2: 'ב׳',
  3: 'ג׳',
  4: 'ד׳',
  5: 'ה׳',
  6: 'ו׳',
  7: 'ש׳',
};

export const monthsTitles: any = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

export const yearsTitles: any[] = [
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
  '2006',
  '2005',
  '2004',
  '2003',
  '2002',
  '2001',
  '2000',
];

const days: string[] = [
  'יום ראשון',
  'יום שני',
  'יום שלישי',
  'יום רביעי',
  'יום חמישי',
  'יום שישי',
  'יום שבת',
];

export const getDealsOption = (tableArray: string[]) => {
  return tableArray.find(
    (value) =>
      value === '1+1' ||
      value === 'הנחה % / ₪' ||
      value === 'הנחה חודשית' ||
      'מתנה חודשית'
  );
};

export const getErrorRetail = (error: any): string => {
  const parsedError = JSON.parse(JSON.stringify(error));
  if (parsedError?.data?.errors) {
    const errorText = Object.values(parsedError.data.errors)[0];
    if (errorText && typeof errorText === 'string') {
      return errorText;
    } else {
      return '';
    }
  } else {
    return (
      `status: ${error?.originalStatus},\n message: ${error?.status}` || ''
    );
  }
};

export const isNonAsciiUsed = (value: string) => !/^[\x00-\x7F]+$/g.test(value);

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = function (e) {
      if (e.target?.result) {
        const blob = new Blob([e.target?.result]);
        resolve(blob);
      }
    };
  });
};

export const calculateProductsPrice = (
  product: IProduct,
  mainDiscount: number | string | undefined
) => {
  const totalPrice = Math.round(
    Number(product.item_count) * Number(product.price_per_item)
  );

  const priceWithDiscount =
    (totalPrice / 100) *
    (100 - Number(product.client_discount || mainDiscount || 0));

  return priceWithDiscount;
};

export const focusTargetFunction = (id: string) => {
  const elem = document.getElementById(id);
  elem?.focus();
};

export function setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
  obj[key] = value;
}

export const getDateFormat = (secondsDate: number | undefined) => {
  if (!secondsDate) return '';
  const resultDate = moment(toDateTime(secondsDate)).format('DD/MM/YYYY');
  if (resultDate !== 'Invalid date') return resultDate;
  return '';
};

export const getDateFormatSecond = (msDate: number | undefined) => {
  if (!msDate) return '';
  const resultDate = moment(msDate, 's').format('MM/DD/YYYY');
  if (resultDate !== 'Invalid date') return resultDate;
  return '';
};

export function toDateTime(secs: any) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}

export const getServerDateFormat = (
  msDate: string | number | undefined | Date
): string => {
  if (!msDate) return '';
  const resultDate = moment(msDate).format('YYYY-MM-DD');
  if (resultDate !== 'Invalid date') return resultDate;
  return '';
};

export const getDayTitle = (): string => {
  return days[new Date().getDay()];
};

export const getTimeFormat = (msDate: number | undefined): string => {
  if (!msDate) return '';
  const resultDate = moment(msDate).format('HH:mm');
  if (resultDate !== 'Invalid date') return resultDate;
  return '';
};

export const getDistributions = (data: any[] | string | undefined): string => {
  if (!data) return '-';
  if (typeof data === 'string') return data;
  return data.map((dis) => dayTitles[dis.day]).join(',');
};

export const getOrderStatus = (
  statusId: number | string | undefined
): OrderStatus | undefined => {
  if (!statusId) return undefined;
  const statusInfo = Object.entries(ordersStatuses).find(
    ([key, info]) => info.id === Number(statusId)
  );
  if (!statusInfo) return undefined;

  return statusInfo[1] as OrderStatus;
};

function toBinary(string: any) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  const charCodes = new Uint8Array(codeUnits.buffer);
  let result = '';
  for (let i = 0; i < charCodes.byteLength; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

function fromBinary(binary: any) {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  const charCodes = new Uint16Array(bytes.buffer);
  let result = '';
  for (let i = 0; i < charCodes.length; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

export function base64toBlob(base64Data: any, contentType: any) {
  contentType = contentType || '';
  let sliceSize = 1024;
  const str = toBinary(base64Data);
  let byteCharacters = decodeURIComponent(escape(atob(str)));
  let bytesLength = byteCharacters.length;
  let slicesCount = Math.ceil(bytesLength / sliceSize);
  let byteArrays = new Array(slicesCount);
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let begin = sliceIndex * sliceSize;
    let end = Math.min(begin + sliceSize, bytesLength);

    let bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

const baseUrl = 'https://balkan-api.dev.bsx-group.com';

export const exportXlsx = async (url: string, token: string, params: any) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  try {
    const response = await fetch(
      baseUrl + url + '?' + new URLSearchParams(params),
      requestOptions
    );
    if (!response.ok) throw new Error('Failed to load File');
    const parsedResponse = await response.blob();
    const blob = new Blob([parsedResponse], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `report_${new Date().getTime()}.xlsx`;
    link.click();
  } catch (e: any) {
    console.log(e.message);
  }
};

export const resetFormById = (id: string): void => {
  const formRef: any = document.getElementById(id);
  if (formRef && formRef.reset && typeof formRef.reset === 'function') {
    formRef.reset();
  }
};

export function dataURLtoFile(dataurl: string, filename: string) {
  var arr: any = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const getPercentage = (partialValue: number, totalValue: number) => {
  return (100 * partialValue) / totalValue;
};

export const sleep = (timeMs: number) =>
  new Promise((res, rej) => {
    setTimeout(res, timeMs);
  });

export const round = (target: number, tick: number) =>
  Math.round(target / tick) * tick;
