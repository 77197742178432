import { RootState } from '../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  IDetailSpecialOffer,
  ISpecialOfferData,
} from '../models/ISpecialOffer/ISpecialOffer';

export const specialOfferApi = createApi({
  reducerPath: 'specialOfferApi',
  tagTypes: ['Line'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getSpecialOffer: build.query<
      any,
      {
        from_date: string;
        to_date: string;
        page: number;
        search: string;
        type: string;
      }
    >({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ from_date, to_date, page, search, type }) => ({
        url: '/api/special-offer/index',
        method: 'GET',
        params: {
          from_date,
          to_date,
          page,
          search,
          type,
        },
      }),
    }),
    detailSpecialOffer: build.query<IDetailSpecialOffer, any>({
      transformResponse: (response: { data: IDetailSpecialOffer }) =>
        response.data,
      query: (id) => ({
        url: `/api/special-offer/${id}`,
        method: 'GET',
      }),
    }),
    updateSpecialOffer: build.mutation<
      any,
      { formData: ISpecialOfferData; id: number }
    >({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ formData, id }) => ({
        url: `/api/special-offer/${id}`,
        method: 'POST',
        body: formData,
      }),
    }),
    createSpecialOffer: build.mutation<any, ISpecialOfferData>({
      transformResponse: (response: { data: any }) => response.data,
      query: (formData) => ({
        url: '/api/special-offer/create',
        method: 'POST',
        body: formData,
      }),
    }),
    deleteSpecialOffer: build.mutation<any, { id: number }>({
      transformResponse: (response: { data: any }) => response.data,
      query: (id) => {
        return {
          url: '/api/special-offer/remove',
          method: 'DELETE',
          params: { id },
        };
      },
    }),
  }),
});
