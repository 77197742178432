import NotificationService from './notifications';
import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createStandaloneToast } from '@chakra-ui/react';
import { userApi } from '../../services/user';
const toast = createStandaloneToast();
// const notifications = new NotificationService();

export const notificationMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action: PayloadAction) => {
    switch (action.type) {
      case 'user/connectMessaging':
        NotificationService.connect(api);
        break;
      default:
        return next(action);
    }
  };
