import { Box, Text } from '@chakra-ui/react';

interface Props {
  title: string;
  text: string;
  onClose: () => void;
}
const Notification = ({ title, text, onClose }: Props) => {
  return (
    <Box borderRadius='10px' bg='white' onClick={onClose}>
      <Box
        p='20px'
        borderRadius='10px'
        minH='100px'
        minW='200px'
        bg='#8913083d'
        boxShadow='0 4px 8px 0 rgba(52, 27, 24, 0.15)'
      >
        <Text mb='15px' fontWeight='bold' fontSize='16px' color='#891308'>
          {title}
        </Text>
        <Text fontSize='18px' color='black'>
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export default Notification;
