import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ILogin, IMenuCounters, IUser } from '../models/IUser/IUser';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    login: build.mutation<IUser, ILogin>({
      transformResponse: (response: { data: IUser }) => response.data,
      query: (data) => ({
        url: '/api/login',
        method: 'POST',
        body: data,
      }),
    }),
    forgotPassword: build.mutation<any, { email: string }>({
      transformResponse: (response: { data: any }) => response.data,
      query: (email) => ({
        url: '/api/forgot-password',
        method: 'POST',
        body: email,
      }),
    }),
    resetPassword: build.mutation<
      any,
      {
        email: string;
        password: string;
        token: string;
        password_confirmation: string;
      }
    >({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ email, password, token, password_confirmation }) => ({
        url: '/api/reset-password',
        method: 'POST',
        body: { email, password, token, password_confirmation },
      }),
    }),
    logout: build.mutation<any, string>({
      query: () => ({
        url: '/api/logout',
        method: 'POST',
      }),
    }),
    getUser: build.query<IUser, string>({
      transformResponse: (response: { data: IUser }) => response.data,
      query: () => ({
        url: '/api/profile',
        method: 'GET',
      }),
    }),
    getMenuCounters: build.query<IMenuCounters, string>({
      transformResponse: (response: { data: IMenuCounters }) => response.data,
      query: () => ({
        url: '/api/profile/counter',
        method: 'GET',
      }),
    }),
  }),
});
