import { useEffect, useMemo, useState } from 'react';
import { notificationsApi } from '../../../services/notification';

interface Props {
  id?: string | number;
  onClose: () => void;
}

const useModalSurvey = ({ id, onClose }: Props) => {
  const [firstRadioValue, setFirstRadioValue] = useState('');
  const [getQuestion, { data: question, isLoading: isLoadingQuestions }] =
    notificationsApi.useLazyGetQuestionQuery();
  const [postQuestion, { isLoading }] =
    notificationsApi.usePostQuestionMutation();

  const firstRadioOptions = useMemo(() => {
    return question
      ? question.variables.map((item) => ({
          label: item.variable,
          value: item.id,
        }))
      : [];
  }, [question]);

  const handleClickSendReply = () => {
    if (id)
      postQuestion({ id: Number(id), variable_id: Number(firstRadioValue) })
        .unwrap()
        .then(() => onClose());
  };

  useEffect(() => {
    if (id) {
      getQuestion({ id: Number(id) });
    }
  }, [id]);

  return {
    question,
    setFirstRadioValue,
    firstRadioValue,
    firstRadioOptions,
    handleClickSendReply,
    isLoading,
    isLoadingQuestions,
  };
};

export default useModalSurvey;
