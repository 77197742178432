import { ICity, IArea, IAreaCode } from './../models/IAreas/IAreas';
import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const areasApi = createApi({
  reducerPath: 'areasApi',
  tagTypes: ['Area', 'City'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getAreas: build.query<IArea[], any>({
      transformResponse: (response: { data: IArea[] }) => response.data,
      query: () => ({
        url: '/api/area',
        method: 'GET',
      }),
    }),
    getAreaCodes: build.query<IAreaCode[], any>({
      transformResponse: (response: { data: IAreaCode[] }) => response.data,
      query: () => ({
        url: '/api/agents/area-codes',
        method: 'GET',
      }),
    }),
    getCities: build.query<ICity[], any>({
      transformResponse: (response: { data: ICity[] }) => response.data,
      query: ({ area_id = '' }) => ({
        url: '/api/city',
        method: 'GET',
        params: {
          area_id,
        },
      }),
    }),
  }),
});
