import { OrderProduct } from '../models/IOrder/IOrder';
import { RootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { driversApi } from '../services/drivers';
import {
  IDriverOrderItems,
  IShowOrderForDriver,
} from '../models/IDriver/IDriver';

interface OrderState {
  products: OrderProduct[];
  initialRequestDone: boolean;
}

const initialState: OrderState = {
  products: [],
  initialRequestDone: false,
};

export const returnOrdersSlice = createSlice({
  name: 'returnOrder',
  initialState,
  reducers: {
    changeReturnItem: (
      state,
      action: PayloadAction<{ id: number; reason: string }>
    ) => {
      const { id, reason } = action.payload;
      state.products = state.products.map((product) =>
        product.id === id ? { ...product, reason } : product
      );
    },
    addProduct: (state, action: PayloadAction<any[]>) => {
      state.products = action.payload;
    },
    resetInitialRequest: (state) => {
      state.initialRequestDone = false;
    },
    addOrderProduct: (
      state,
      action: PayloadAction<{ product: OrderProduct; reason?: string }>
    ) => {
      const { product, reason } = action.payload;
      state.products = [
        ...state.products,
        { ...product, count: 1, reason: reason ? reason : 'פגום' },
      ];
    },
    deleteOrderProduct: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.products = state.products.filter((product) => product.id !== id);
    },
    increaseProductCount: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      state.products = state.products.map((product) =>
        product.id === id
          ? {
              ...product,
              count: product.count && product.count + 1,
            }
          : product
      );
    },
    toggleAmountDriverReason: (
      state,
      action: PayloadAction<{ id: number; value: 0 | 1 }>
    ) => {
      const { id, value } = action.payload;
      let nextState = [];
      let deleteItem = false;
      nextState = state.products.map((product) => {
        if (product.id === id) {
          if (!product.is_reason_damaged && value === 0) deleteItem = true;
          return {
            ...product,
            is_reason_amount: value,
          };
        }
        return product;
      });
      if (deleteItem) {
        state.products = nextState.filter((prev) => prev.id !== id);
      } else {
        state.products = nextState;
      }
    },
    toggleDamagedDriverReason: (
      state,
      action: PayloadAction<{ id: number; value: 0 | 1 }>
    ) => {
      const { id, value } = action.payload;
      let nextState = [];
      let deleteItem = false;
      nextState = state.products.map((product) => {
        if (product.id === id) {
          if (!product.is_reason_amount && value === 0) deleteItem = true;
          return {
            ...product,
            is_reason_damaged: value,
          };
        }
        return product;
      });
      if (deleteItem) {
        state.products = nextState.filter((prev) => prev.id !== id);
      } else {
        state.products = nextState;
      }
    },
    changeProductReturnCount: (
      state,
      action: PayloadAction<{ id: number; count: string }>
    ) => {
      const { id, count } = action.payload;
      state.products = state.products.map((product) =>
        product.id === id ? { ...product, count: Number(count) } : product
      );
    },
    decreaseProductCount: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const isMinimum =
        state.products.find((product) => product.id === id)?.count === 1;

      state.products = !isMinimum
        ? state.products.map((product) =>
            product.id === id
              ? { ...product, count: product.count && product.count - 1 }
              : product
          )
        : state.products.filter((product) => product.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      driversApi.endpoints.showOrderForDriver.matchFulfilled,
      (state, action: PayloadAction<IShowOrderForDriver>) => {
        const { payload } = action;
        if (!state.initialRequestDone) {
          state.products = payload.return_order_items || [];
          state.initialRequestDone = true;
        }
      }
    );
  },
});

export const getOrderProducts = (state: RootState) =>
  state.returnOrder.products;

export const {
  changeReturnItem,
  addOrderProduct,
  increaseProductCount,
  decreaseProductCount,
  changeProductReturnCount,
  deleteOrderProduct,
  addProduct,
  toggleAmountDriverReason,
  toggleDamagedDriverReason,
  resetInitialRequest,
} = returnOrdersSlice.actions;

export default returnOrdersSlice.reducer;
