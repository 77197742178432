import {
  Center,
  Flex,
  FlexProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
  Skeleton,
} from '@chakra-ui/react';
import Pagination from '../Pagination/Pagination';
import NotificationItem from './NotificationItem/NotificationItem';
import useNotificationPopup from './useNotificationPopup';

const NotificationsPopup = ({
  onClose,
  ...props
}: Omit<ModalProps, 'children'>) => {
  const { notifications, isLoading, refetch, onPageChange, total } =
    useNotificationPopup();

  return (
    <>
      <Modal {...props} onClose={onClose} size='lg'>
        <ModalOverlay />
        <ModalContent {...content}>
          <ModalCloseButton {...modalClose} size='17px' />
          <ModalBody>
            <Flex {...wrapItems}>
              {notifications?.length === 0 && !isLoading && (
                <Center
                  w='full'
                  h='full'
                  fontSize='30px'
                  opacity='0.5'
                  color='black'
                  fontWeight='bold'
                >
                  רשימה ריקה
                </Center>
              )}
              {isLoading ? (
                [0, 1, 2, 3, 4].map((_, i) => (
                  <Skeleton
                    key={i}
                    w='full'
                    maxW='900px'
                    height='140px'
                    minH='140px'
                    borderRadius='20px'
                    startColor='#E2E8F0'
                    endColor='lightgrey'
                    _first={{ mt: '6px' }}
                    mt='18px'
                  />
                ))
              ) : (
                <>
                  {notifications.map((item) => (
                    <NotificationItem
                      onCloseNotification={onClose}
                      refetchNotifications={refetch}
                      key={item.id}
                      notification={item}
                      autoWidth
                    />
                  ))}
                </>
              )}
            </Flex>
            <Flex w='full' justify='flex-end' pl='50px'>
              <Pagination m='0px' total={total} onPageChange={onPageChange} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const wrapItems: FlexProps = {
  flexDir: 'column',
  m: '25px 0',
  maxH: '560px',
  h: 'calc(100% - 60px)',
  overflowY: 'auto',
  sx: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '5px',
      borderRadius: '15px',
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `brand.grey`,
      borderRadius: '15px',
    },
  },
  align: 'center',
};

const content: ModalContentProps = {
  borderRadius: '40px !important',
  maxH: '700px',
  h: 'calc(100% - 40px)',
  minW: '700px',
  w: 'calc(100% - 40px)',
  maxW: '1110px',
  m: 'auto',
  overflow: 'hidden',
};

const modalClose: ModalContentProps = {
  _focus: { boxShadow: 'none' },
  _hover: { bg: 'transparent' },
  color: 'brand.black',
  m: '25px',
  zIndex: '9',
};
export default NotificationsPopup;
