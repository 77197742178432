export const Button = {
  variants: {
    first: {
      w: 'full',
      borderRadius: '15px',
      h: '50px',
      bg: '#891308',
      borderColor: 'white',
      border: '1px',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '20px',
    },
    second: {
      w: 'full',
      borderRadius: '15px',
      h: '50px',
      bg: '#891308',
      borderColor: 'rgba(255, 255, 255, 0.7)',
      border: '1px',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '20px',
      color: 'brand.white',
    },
    third: {
      _active: {
        bg: 'brand.bordo',
        color: 'brand.white',
      },
      p: '6px 16px',
      borderRadius: '30px',
      h: '29px',
      borderColor: 'brand.bordo',
      border: '2px',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '20px',
      bg: 'brand.white',
    },
  },
};
