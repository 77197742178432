export interface OrderStatus {
  title: string;
  id: number;
  color: string;
}

interface OrderStatuses {
  new: OrderStatus;
  updated: OrderStatus;
  waitingApproval: OrderStatus;
  waitingSupply: OrderStatus;
  onWay: OrderStatus;
  delivered: OrderStatus;
  canceled: OrderStatus;
}

export const ordersStatuses: OrderStatuses = {
  new: { title: 'הזמנה חדשה', id: 1, color: 'brand.bordo' },
  updated: { title: 'ההזמנה עודכנה', id: 2, color: 'brand.bordo' },
  waitingApproval: { title: 'ממתין לאישור', id: 3, color: 'brand.bordo' },
  waitingSupply: { title: 'ממתין לאספקה', id: 4, color: 'brand.sky' },
  onWay: { title: 'בדרך ללקוח', id: 5, color: 'brand.sky' },
  delivered: { title: 'נמסר', id: 6, color: 'brand.green' },
  canceled: { title: 'בוטל', id: 7, color: 'brand.bordo' },
};

export const approvedStatuses = {
  notYetConfirmed: { title: 'טרם אושר', id: 1, color: 'brand.sky' },
  closingDown: { title: 'נסגר', id: 2, color: 'brand.bordo' },
  happiness: { title: 'אושר', id: 3, color: 'brand.green' },
};

export interface ResetFilters {
  CLIENT_FILTERS: string;
  AGENT_TASKLIST_FILTERS: string;
  AGENT_HOME_FILTERS: string;
  AGENT_SEND_FILTERS: string;
  CLIENT_EQUIPMENT_FILTERS: string;
  CLIENT_USERS_FILTERS: string;
  CARGO_TRUCK_FILTERS: string;
  INFORMATION_DRIVERS_FILTERS: string;
  INFORMATION_ROUTE_FILTERS: string;
  ORDERS_FILTERS: string;
  PRODUCTS_FILTERS: string;
  SEND_NOTIFICATIONS_FILTERS: string;
  PAYMENT_STATISTICS_FILTERS: string;
  PRIORITIZE_SHIPMENTS_FILTERS: string;
  ADMIN_DRIVER_TASKS_FILTERS: string;
  CONCENTRATION_REPORTS_FILTERS: string;
  IN_PRIORITIZE_SHIPMENTS_FILTERS: string;
}

export const resetFilters: ResetFilters = {
  CLIENT_FILTERS: 'clientFilters',
  AGENT_TASKLIST_FILTERS: 'agentTaskListFilters',
  AGENT_HOME_FILTERS: 'AGENT_HOME_FILTERS',
  AGENT_SEND_FILTERS: 'AGENT_SEND_FILTERS',
  CLIENT_EQUIPMENT_FILTERS: 'CLIENT_EQUIPMENT_FILTERS',
  CLIENT_USERS_FILTERS: 'CLIENT_USERS_FILTERS',
  CARGO_TRUCK_FILTERS: 'CARGO_TRUCK_FILTERS',
  INFORMATION_DRIVERS_FILTERS: 'INFORMATION_DRIVERS_FILTERS',
  INFORMATION_ROUTE_FILTERS: 'INFORMATION_ROUTE_FILTERS',
  ORDERS_FILTERS: 'ORDERS_FILTERS',
  PRODUCTS_FILTERS: 'PRODUCTS_FILTERS',
  SEND_NOTIFICATIONS_FILTERS: 'SEND_NOTIFICATIONS_FILTERS',
  PAYMENT_STATISTICS_FILTERS: 'PAYMENT_STATISTICS_FILTERS',
  PRIORITIZE_SHIPMENTS_FILTERS: 'PRIORITIZE_SHIPMENTS_FILTERS',
  ADMIN_DRIVER_TASKS_FILTERS: 'ADMIN_DRIVER_TASKS_FILTERS',
  CONCENTRATION_REPORTS_FILTERS: 'CONCENTRATION_REPORTS_FILTERS',
  IN_PRIORITIZE_SHIPMENTS_FILTERS: 'IN_PRIORITIZE_SHIPMENTS_FILTERS',
};

const keys = [
  'ArrowUp',
  'ArrowDown',
  'Backspace',
  'Delete',
  'Tab',
  'End',
  'Home',
  'Enter',
  'ArrowLeft',
  'ArrowRight',
  'Shift',
  'Ctrl',
  'Alt',
  'Meta',
];

export const numberPattern = (event: any) => {
  if (!/^[0-9 | +]+$/.test(event.key) && !keys.includes(event.key)) {
    event.preventDefault();
  }
};

export const emailPattern =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// export const emailPattern =
//   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailStringPattern =
  '^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$';

export const notificationTypes = {
  BLOCKED: 'blocked',
  NEW_ORDER: 'new_order',
};
