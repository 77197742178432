import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  IAgentPricingData,
  IClientSalesResponse,
  IDailySalesResponse,
  IListReportsClientActivity,
  IlistReportsGiuls,
  IReportResponse,
  IReportsAgentsGoals,
  IReportsProductPricingData,
} from '../models/IReport/IReport';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  tagTypes: ['Report'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getReportList: build.query<
      IReportResponse,
      {
        search: string;
        page: number;
        status: string;
        to_date?: string;
        from_date?: string;
        city_id?: string;
        product_id?: string;
      }
    >({
      transformResponse: (response: { data: IReportResponse }) => response.data,
      query: ({
        search,
        page,
        status,
        to_date,
        from_date,
        city_id,
        product_id,
      }) => ({
        url: '/api/reports',
        method: 'GET',
        params: {
          search,
          page,
          status,
          to_date,
          from_date,
          city_id,
          product_id,
        },
      }),
    }),

    changeStatus: build.mutation<any, { reportId: string; is_read: number }>({
      query: ({ reportId, is_read }) => ({
        url: `/api/reports/${reportId}/change-status`,
        method: 'POST',
        body: {
          is_read,
        },
      }),
    }),
    reportsProductPricing: build.query<
      IReportsProductPricingData,
      { search: string; fromDate: string; toDate: string; client_id: string }
    >({
      transformResponse: (response: { data: IReportsProductPricingData }) =>
        response.data,
      query: ({ fromDate, toDate, search, client_id }) => ({
        url: '/api/product/pricing',
        method: 'GET',
        params: { fromDate, toDate, search, client_id },
      }),
    }),
    reportsAgentsGoals: build.query<
      IReportsAgentsGoals,
      { search: string; date: string; page: number }
    >({
      transformResponse: (response: IReportsAgentsGoals) => response,
      query: ({ date, search, page }) => ({
        url: '/api/agents/goals',
        method: 'GET',
        params: { search, date, page },
      }),
    }),
    listReportsGiuls: build.query<
      IlistReportsGiuls,
      { search: string; page: number }
    >({
      transformResponse: (response: { data: IlistReportsGiuls }) =>
        response.data,
      query: ({ search, page }) => ({
        url: '/api/list-reports/giuls',
        method: 'GET',
        params: { search, page },
      }),
    }),
    getClientSales: build.query<
      IClientSalesResponse,
      {
        search: string;
        to_date: string;
        from_date: string;
        area_id: string;
        client_id: string;
      }
    >({
      transformResponse: (response: { data: IClientSalesResponse }) =>
        response.data,
      query: ({ from_date, to_date, search, area_id, client_id }) => ({
        url: '/api/list-reports/client-sales',
        method: 'GET',
        params: { search, from_date, to_date, area_id, client_id },
      }),
    }),
    getDailySales: build.query<IDailySalesResponse, { page: number }>({
      transformResponse: (response: { data: IDailySalesResponse }) =>
        response.data,
      query: ({ page }) => ({
        url: '/api/list-reports/daily-sales',
        method: 'GET',
        params: { page },
      }),
    }),
    updateNewAgentMessageCounter: build.mutation({
      query: () => ({
        url: '/api/reports/open-page',
        method: 'POST',
      }),
    }),
    agentPricing: build.query<
      IAgentPricingData,
      { page: number; search: string; city_id: string; category_id: string }
    >({
      transformResponse: (response: { data: IAgentPricingData }) =>
        response.data,
      query: ({ page, search, city_id, category_id }) => ({
        url: '/api/agent/pricing',
        method: 'GET',
        params: { page, search, city_id, category_id },
      }),
    }),
    listReportsClientActivity: build.query<
      IListReportsClientActivity,
      {
        search: string;
        area_id: number;
        agent_id: number;
        city_id: number;
        to_date: string;
        from_date: string;
        ids: number[];
        category_id: number;
        approved: number;
        page: number;
      }
    >({
      transformResponse: (response: { data: IListReportsClientActivity }) =>
        response.data,
      query: ({
        search,
        area_id,
        agent_id,
        city_id,
        to_date,
        from_date,
        approved,
        ids,
        category_id,
        page,
      }) => ({
        url: '/api/list-reports/client-activity',
        method: 'GET',
        params: {
          search,
          area_id,
          agent_id,
          city_id,
          to_date,
          from_date,
          approved,
          ids,
          category_id,
          page,
        },
      }),
    }),
  }),
});
