import { Input as ChakraInput, InputProps } from '@chakra-ui/react';

export const Input = (props: InputProps) => (
  <ChakraInput
    borderRadius="15px"
    height="50px"
    backgroundColor="brand.grey"
    borderColor="white"
    border="1px"
    {...props}
  />
);
