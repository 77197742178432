import {
  Box,
  BoxProps,
  Center,
  CenterProps,
  Flex,
  FlexProps,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import portret from '../../../Images/PNG/Portret-small.png';
import textPortret from '../../../Images/SVG/TextPortret.svg';
import bell from '../../../Images/SVG/ic_bell.svg';
import logoutIcon from '../../../Images/SVG/Logout.svg';
import NotificationsPopup from '../NotificationsPopup/NotificationsPopup';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { paths } from '../../../routes/paths';
import {
  setUserRole,
  UserRole,
  logout,
  selectNotificationListener,
} from '../../../redux/userSlice';
import useTablet from '../../../hooks/useTablet';
import { notificationsApi } from '../../../services/notification';
import { userApi } from '../../../services/user';
import { useEffect } from 'react';

const Header: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userRole = useAppSelector((state) => state.user.info.role);
  const { data: userData, refetch: refetchUserData } = userApi.useGetUserQuery(
    '',
    {
      refetchOnMountOrArgChange: true,
      // pollingInterval: 10000,
    }
  );
  const userName = useAppSelector((state) => state.user.info.full_name);
  const updateNeaded = useAppSelector(selectNotificationListener);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isTablet = useTablet();
  const [readAllNotifications] =
    notificationsApi.useReadAllNotificationsMutation();
  const [apiLogout] = userApi.useLogoutMutation();

  useEffect(() => {
    refetchUserData();
  }, [updateNeaded]);

  const openNotifications = () => {
    if (userRole === 'driver') {
      navigate(`/${userRole}${paths.notifications}`);
    } else {
      onOpen();
      readAllNotifications('');
    }
  };

  const onCloseNotifications = () => {
    onClose();
    dispatch(
      notificationsApi.endpoints.getNotifications.initiate({
        search: '',
        page: 1,
      })
    );
  };

  const onRoleChange = (option: any) => {
    dispatch(setUserRole(option.value));
  };

  const handleLogout = () => {
    apiLogout('');
    dispatch(logout());
  };

  useEffect(refetchUserData, [isOpen]);

  return (
    <Center {...headerStyle(isTablet)} id='header'>
      <NotificationsPopup isOpen={isOpen} onClose={onCloseNotifications} />
      <Flex>
        <Image src={portret} alt='portret' h={isTablet ? '60px' : '50px'} />
        <Image src={textPortret} w='102px' alt='text' mr='15px' />
      </Flex>

      <Flex align='center'>
        <Box onClick={openNotifications} {...wrapNotification}>
          <Image src={bell} alt='bell' />
          {userData && userData.unread_notifications > 0 && (
            <Center {...notificationStyle}>
              {userData?.unread_notifications}
            </Center>
          )}
        </Box>
        <Text
          color='brand.yellow'
          fontSize='18px'
          m='0 20px'
          // textTransform='uppercase'
        >
          {userName}
        </Text>
        <Image
          {...hoverStyle}
          src={logoutIcon}
          onClick={handleLogout}
          alt='logout'
        />
      </Flex>
    </Center>
  );
};

const hoverStyle: FlexProps = {
  _hover: {
    cursor: 'pointer',
  },
};

const wrapNotification: BoxProps = {
  pos: 'relative',
  _hover: {
    cursor: 'pointer',
  },
};

const headerStyle = (tablet: boolean) =>
  ({
    w: 'full',
    h: tablet ? '60px' : '80px',
    bgImage: 'linear-gradient(90deg, #6e1105 50%, #891308 50%)',
    bgSize: '2rem 2rem',
    justifyContent: 'space-between',
    p: '15px 15px 15px 30px',
  } as FlexProps);

const notificationStyle: CenterProps = {
  fontSize: '12px',
  color: 'brand.bordo',
  pos: 'absolute',
  w: '24px',
  h: '18px',
  bg: 'brand.yellow',
  borderRadius: '33px',
  fontWeight: 'bold',
  top: '-5px',
  right: '-15px',
};

export default Header;
