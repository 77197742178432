import { RootState } from '../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  IAgentDashboard,
  IDashBoardTiful,
  IDashboardTodayOrders,
  IGraphTiful,
  INoamDashboard,
  ITableDashboardList,
  ITifulDashboardGraph,
  ManagerDashboardData,
} from '../models/IDashBoard/IDashBoard';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    counterTifulDashboard: build.query<IDashBoardTiful, string>({
      transformResponse: (response: { data: IDashBoardTiful }) => response.data,
      query: () => ({
        url: '/api/tiful/dashboard',
        method: 'GET',
      }),
    }),
    pieGraphTifulDashboard: build.query<
      IGraphTiful[],
      { from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: IGraphTiful[] }) => response.data,
      query: ({ from_date, to_date }) => ({
        url: '/api/tiful/dashboard/pie',
        method: 'GET',
        params: { from_date, to_date },
      }),
    }),
    popularProductsGraphTifulDashboard: build.query<
      IGraphTiful[],
      { from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: IGraphTiful[] }) => response.data,
      query: ({ from_date, to_date }) => ({
        url: '/api/tiful/dashboard/pupular-products',
        method: 'GET',
        params: { from_date, to_date },
      }),
    }),
    getManagerSectionData: build.query<ManagerDashboardData, string>({
      transformResponse: (response: { data: ManagerDashboardData }) =>
        response.data,
      query: () => ({
        url: '/api/manager/dashboard',
        method: 'GET',
      }),
    }),
    noamDashboard: build.query<INoamDashboard, string>({
      transformResponse: (response: { data: INoamDashboard }) => response.data,
      query: () => ({
        url: '/api/noam/dashboard',
        method: 'GET',
      }),
    }),
    noamDashboardTotalSale: build.query<any, { type: string }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ type }) => ({
        url: '/api/noam/dashboard/total-sale',
        method: 'GET',
        params: { type },
      }),
    }),
    agentDashboardTotalSale: build.query<any, { type: string }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ type }) => ({
        url: '/api/agent/dashboard/total-sale',
        method: 'GET',
        params: { type },
      }),
    }),
    noamDashboardCountSale: build.query<any, { type: string }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ type }) => ({
        url: '/api/noam/dashboard/count-sale',
        method: 'GET',
        params: { type },
      }),
    }),
    getTifulDashboardGraph: build.query<
      ITifulDashboardGraph[],
      { type: string; from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: ITifulDashboardGraph[] }) =>
        response.data,
      query: ({ type, from_date, to_date }) => ({
        url: '/api/tiful/dashboard/graph',
        method: 'GET',
        params: { type, from_date, to_date },
      }),
    }),
    managerDashboardGraph: build.query<
      ITifulDashboardGraph[],
      { type: string; from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: ITifulDashboardGraph[] }) =>
        response.data,
      query: ({ type, from_date, to_date }) => ({
        url: 'api/manager/dashboard/graph',
        method: 'GET',
        params: { type, from_date, to_date },
      }),
    }),
    managerDashboardList: build.query<
      ITableDashboardList[],
      { type: string; from_date: string; to_date: string; agent_id: string }
    >({
      transformResponse: (response: { data: ITableDashboardList[] }) =>
        response.data,
      query: ({ type, from_date, to_date, agent_id }) => ({
        url: 'api/manager/dashboard/list',
        method: 'GET',
        params: { agent_id, type, to_date, from_date },
      }),
    }),
    managerGraphByAgent: build.query<
      any[],
      { type: string; from_date: string; to_date: string; agent_id: string }
    >({
      transformResponse: (response: { data: any[] }) => response.data,
      query: ({ type, from_date, to_date, agent_id }) => ({
        url: '/api/manager/dashboard/graph-by-agent',
        method: 'GET',
        params: { agent_id, type, to_date, from_date },
      }),
    }),
    agentDashboard: build.query<IAgentDashboard, any>({
      transformResponse: (response: { data: IAgentDashboard }) => response.data,
      query: () => ({
        url: '/api/agent/dashboard',
        method: 'GET',
      }),
    }),
    dashboardTodayOrders: build.query<
      IDashboardTodayOrders[],
      { from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: IDashboardTodayOrders[] }) =>
        response.data,
      query: ({ from_date, to_date }) => ({
        url: 'api/agent/dashboard/today-orders',
        method: 'GET',
        params: { to_date, from_date },
      }),
    }),
  }),
});
