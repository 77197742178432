import {
  Box,
  BoxProps,
  Center,
  CenterProps,
  Flex,
  FlexProps,
  Image,
  ImageProps,
  useMediaQuery,
  Text,
  TextProps,
} from '@chakra-ui/react';
import buttonMenuIcon from '../../../Images/SVG/active-ic-menu.svg';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import useLinks from './useLinks/useLinks';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setIsOpenMenu } from '../../../redux/homeSlice';
import { useEffect } from 'react';
import useTablet from '../../../hooks/useTablet';
import { getDateFormat, getDayTitle } from '../../../helpers/common';
import moment from 'moment';

const Menu = () => {
  const isTabletWidth = useTablet();
  const isOpenMenu = useAppSelector((state) => state.home.menuIsOpened);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const links = useLinks();
  const activeLink = location.pathname;

  const handleToggleMenu = () => {
    // if (!isOpenMenu && isTabletWidth) return;
    dispatch(setIsOpenMenu(!isOpenMenu));
  };

  useEffect(() => {
    if (isTabletWidth) {
      dispatch(setIsOpenMenu(false));
    } else {
      dispatch(setIsOpenMenu(true));
    }
  }, [isTabletWidth]);

  const getIsActive = (item: any) => {
    if (item.pathKey && activeLink.includes(item.pathKey)) {
      return true;
    } else if (item.path === activeLink) {
      return true;
    } else if (activeLink.includes(item.path)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Flex
      minW={isOpenMenu ? '200px' : '80px'}
      id='menu'
      {...wrapMenu}
      h={isTabletWidth ? 'calc(100vh - 70px)' : 'calc(100vh - 90px)'}
    >
      <Center w='full' justifyContent='flex-start' mt='15px' flexDir='column'>
        <Flex align='flex-start' mb={isOpenMenu ? '15px' : '25px'}>
          <Image
            {...imgStyle}
            onClick={handleToggleMenu}
            transform={isOpenMenu ? '' : 'rotate(180deg)'}
          />
          {isOpenMenu && (
            <Box mr='12px' fontSize='12px'>
              <Text fontWeight='bold'>{moment().format('DD/MM/YYYY')}</Text>
              <Text fontSize='15px'>{getDayTitle()}</Text>
            </Box>
          )}
        </Flex>
        <nav>
          <Flex {...wrapMenuBlock} w={isOpenMenu ? '165px' : '46px'}>
            {links.map((item) => (
              <Box
                key={item.id}
                as={NavLink}
                to={item.path}
                justifyContent={isOpenMenu ? 'space-between' : 'center'}
                {...stylesMenu(getIsActive(item))}
              >
                {item.count > 0 ? (
                  <>
                    <Flex {...icStyle}>
                      {item.icon}
                      {isOpenMenu && <Text {...textTab}>{item.title}</Text>}
                    </Flex>
                    {isOpenMenu && (
                      <Center {...countStyle}>{item.count}</Center>
                    )}
                  </>
                ) : (
                  <Flex align='center'>
                    {item.icon}
                    {isOpenMenu && <Text {...textTab}>{item.title}</Text>}
                  </Flex>
                )}
              </Box>
            ))}
          </Flex>
        </nav>
      </Center>
    </Flex>
  );
};

const stylesMenu = (isActive = false) =>
  ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    w: '100%',
    h: '46px',
    p: '9px',
    transition: 'all 0.1s ease-out',

    borderRadius: isActive ? '8px' : 'none',
    color: isActive ? 'brand.white' : 'brand.grey',
    bg: isActive ? 'brand.bordo' : 'brand.white',
  } as BoxProps);

const wrapMenuBlock: FlexProps = {
  flexDir: 'column',
  border: '1px solid #E7E7E7',
  borderRadius: '10px',
  transition: 'all 0.4s ease-out',
  background: 'rgba(255, 255, 255, 0.6)',
  backdropFilter: 'blur(10px)',
};

const icStyle: FlexProps = {
  alignItems: 'center',
};

const wrapMenu: FlexProps = {
  bg: 'brand.white',
  minH: 'calc(100vh - 80px)',
  boxShadow: 'xlx',
  transition: 'all 0.4s ease-out',
  overflowY: 'auto',
  sx: {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '5px',
      borderRadius: '4px',
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `brand.grey`,
      borderRadius: '4px',
    },
  },
};

const imgStyle: ImageProps = {
  src: buttonMenuIcon,
  alt: 'button',
  mt: '2px',
  transition: 'all 0.4s ease-out',
  _hover: { cursor: 'pointer' },
};

const countStyle: CenterProps = {
  bg: 'brand.blue',
  minH: '20px',
  minW: '20px',
  borderRadius: '50%',
  fontSize: '10px',
  fontWeight: 'bold',
  color: 'brand.white',
  pos: 'relative',
};

const textTab: TextProps = {
  pos: 'relative',
  fontSize: '16px',
  lineHeight: '22px',
  mr: '5px',
  maxH: '22px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export default Menu;
