import { memo, useEffect } from 'react';
import {
  Pagination as AjnaPagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
} from '@ajna/pagination';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

interface PaginationProps {
  total: number;
  onPageChange: (page: number) => void;
  m?: string;
  page?: number;
}

const Pagination = ({ total, onPageChange, page, m }: PaginationProps) => {
  const bg = useColorModeValue('brand.blue', 'brand.blue');

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    total,
    initialState: { currentPage: 1, pageSize: 15 },
    limits: {
      outer: 2,
      inner: 1,
    },
  });

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    }
  }, [page]);

  return (
    <Flex flexDir='column' m={m ? m : '50px 0 0 0'}>
      <Flex fontSize='14px' color='brand.text' mb='5px'>
        <Text>סה״כ תוצאות</Text>
        <Text pr='5px'>{total}</Text>
      </Flex>
      <AjnaPagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      >
        <PaginationContainer>
          <PaginationNext {...paginationArrow}>
            <ArrowRightIcon w='8px' h='8px' />
          </PaginationNext>
          <PaginationPageGroup flexDir='row-reverse'>
            {pages.map((page: number) => (
              <PaginationPage
                key={`pagination_page_${page}`}
                page={page}
                _current={{ bg }}
                color={currentPage === page ? 'brand.white' : 'brand.blue'}
                {...itemStyle}
              />
            ))}
          </PaginationPageGroup>
          <PaginationPrevious {...paginationArrow}>
            <ArrowLeftIcon w='8px' h='8px' />
          </PaginationPrevious>
        </PaginationContainer>
      </AjnaPagination>
    </Flex>
  );
};

const itemStyle = {
  w: '26px',
  h: '26px',
  m: '0 2.5px',
  fontSize: '14px',
  fontWeight: 'noraml',
  _hover: { bg: 'brand.blue', color: 'brand.white' },
  _focus: { boxShadow: 'none' },
};

const paginationArrow = {
  w: '26px',
  h: '26px',
  border: '1px solid',
  borderColor: 'brand.blue',
  _focus: { boxShadow: 'none' },
  _hover: { bg: 'brand.blue', color: 'brand.white' },
  color: 'brand.blue',
};

export default memo(Pagination);
