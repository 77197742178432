import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import { useDispatch } from 'react-redux';
import useTablet from '../../../hooks/useTablet';
import RoutesByRole from '../../../routes/RoutesByRole';
import Header from '../../Common/Header/Header';
import Menu from '../../Common/Menu/Menu';
import Login from '../Login/Login';
import { connectMessaging } from '../../../redux/userSlice';
import CheckboxSelect from '../../Common/CheckboxSelect/CheckboxSelect';

const Main = () => {
  const dispatch = useDispatch();
  const isTablet = useTablet();
  const userRole = useAppSelector((state) => state.user.info.role);

  useEffect(() => {
    if (userRole !== 'none') {
      dispatch(connectMessaging());
    }
  }, [userRole]);

  return (
    <Flex id='main' {...wrapStyle} h='100vh'>
      <Header />
      <Flex>
        {/* <Box
          zIndex='999999999'
          pos='absolute'
          w='100vw'
          h='100vh'
          bg='lightgrey'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CheckboxSelect
            onChange={(newValue: any) => console.log(newValue, 'adsfasf')}
            options={[
              { label: 'dsjn', value: '1' },
              { label: 'fff', value: '2' },
              { label: 'ggg', value: '4' },
              { label: 'ggg', value: '5' },
              { label: 'ggg', value: '6' },
              { label: 'ggg', value: '7' },
              { label: 'ggg', value: '8' },
              { label: 'ggg', value: '9' },
              { label: 'ggg', value: '10' },
              { label: 'ggg', value: '11' },
              { label: 'ggg', value: '12' },
              { label: 'ggg', value: '13' },
              { label: 'ggg', value: '14' },
              { label: 'ggg', value: '15' },
            ]}
          />
        </Box> */}
        <Menu />
        <Flex {...getWrapStyle(isTablet)}>
          <RoutesByRole />
        </Flex>
      </Flex>
    </Flex>
  );
};

const getWrapStyle = (isTablet: boolean): FlexProps =>
  ({
    w: 'full',
    h: isTablet ? 'calc(100vh - 70px)' : 'calc(100vh - 90px)',
    maxH: isTablet ? 'calc(100vh - 70px)' : 'calc(100vh - 90px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxW: 'calc(100vw - 80px)',
    sx: {
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '5px',
        borderRadius: '15px',
        backgroundColor: `rgba(0, 0, 0, 0.05)`,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: `brand.grey`,
        borderRadius: '15px',
      },
    },
    css: { '& > div': { overflow: 'unset', maxW: '1150px' } },
  } as FlexProps);

const wrapStyle: FlexProps = {
  bg: 'brand.bgGrey',
  minH: '100vh',
  w: 'full',
  flexDir: 'column',
  color: 'brand.black',
};

export default Main;
