import { IAgent } from './../models/IAgent/IAgent';
import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  ClientCreateThirdStep,
  ClientProductQueryParams,
  IClientSetting,
  IClientsResponse,
  IClientProductResponse,
  IClientState,
  IClientStepFour,
  ICreateClientStepOne,
  ICreateClientStepOneResponse,
  IClientShowUsersData,
  IClient,
  IClientShowUsers,
  IEquipment,
  IClientReports,
  IReports,
  IClientBySearch,
  CreateSelfClientResponse,
  CreateSelfClient,
  IOrderListOnCustomerResponse,
  IEquipmentResponse,
  IClientProduct,
  PaymentInfoResponse,
  IGetStatisticsInLine,
  ClientPaymentResponse,
  PaymentStatistic,
  IClientRefundsReasons,
  IClientRefundsReasonsResponse,
} from '../models/IClient/IClient';
import { IGraphTiful } from '../models/IDashBoard/IDashBoard';

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  tagTypes: ['Client', 'State', 'Category'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getClients: build.query<
      IClientsResponse,
      {
        page: number;
        query: string;
        to_date?: string;
        from_date?: string;
        area_id?: string;
        city_id?: string;
        agent_id?: string;
        sub_agent_id?: string;
        days?: number[] | undefined;
        is_approved?: string;
      }
    >({
      transformResponse: (response: { data: IClientsResponse }) =>
        response.data,
      query: ({
        page,
        query,
        from_date,
        to_date,
        agent_id,
        city_id,
        area_id,
        days,
        is_approved,
        sub_agent_id,
      }) => ({
        url: '/api/clients',
        method: 'GET',
        params: {
          page,
          search: query,
          from_date,
          to_date,
          agent_id,
          city_id,
          area_id,
          days: JSON.stringify(days),
          is_approved,
          sub_agent_id,
        },
      }),
    }),
    updateClients: build.mutation<
      IClientsResponse,
      { formData: IClientSetting; id: number }
    >({
      transformResponse: (response: { data: IClientsResponse }) =>
        response.data,
      query: ({ formData, id }) => ({
        url: `/api/clients/${id}`,
        method: 'POST',
        body: formData,
      }),
    }),
    getAgentClients: build.query<
      IClientsResponse,
      {
        page: number;
        query: string;
        id: string;
        to_date: string;
        from_date: string;
        area_id: string;
        sub_agent_id?: string;
        city_id: string;
        agent_id: string;
        days: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: IClientsResponse }) =>
        response.data,
      query: ({
        page,
        query,
        id,
        from_date,
        to_date,
        agent_id,
        city_id,
        area_id,
        sub_agent_id,
        days,
      }) => ({
        url: `/api/agents/${id}/clients`,
        method: 'GET',
        params: {
          page,
          search: query,
          from_date,
          to_date,
          agent_id,
          city_id,
          area_id,
          days: JSON.stringify(days),
          sub_agent_id,
        },
      }),
    }),
    getClientsShortList: build.query<{ id: number; name: string }[], string>({
      transformResponse: (response: { data: { id: number; name: string }[] }) =>
        response.data,
      query: () => ({
        url: '/api/clients/short-list',
        method: 'GET',
      }),
    }),
    getClientPaymentInfo: build.query<PaymentInfoResponse, string>({
      transformResponse: (response: { data: PaymentInfoResponse }) =>
        response.data,
      query: () => ({
        url: '/api/payments-info',
        method: 'GET',
      }),
    }),
    getClientSetting: build.query<IClientSetting, any>({
      transformResponse: (response: { data: IClientSetting }) => response.data,
      query: (id) => ({
        url: `/api/clients/${id}/setting`,
        method: 'GET',
      }),
    }),
    getShowUsersINClient: build.query<
      IClientShowUsersData,
      { id: number; page: number; query: string }
    >({
      transformResponse: (response: { data: IClientShowUsersData }) =>
        response.data,
      query: ({ id, page, query }) => ({
        url: `/api/clients/${id}/users`,
        method: 'GET',
        params: {
          page,
          search: query,
        },
      }),
    }),
    createUsersINClient: build.mutation<
      any,
      { formData: IClientShowUsers; id: number }
    >({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ formData, id }) => ({
        url: `/api/clients/${id}/users`,
        method: 'POST',
        body: formData,
      }),
    }),
    updateUsersINClient: build.mutation<
      any,
      { formData: IClientShowUsers; client_id: number; user_id: number }
    >({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ formData, client_id, user_id }) => ({
        url: `/api/clients/${client_id}/users/${user_id}`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteUsersINClient: build.mutation<
      IClient,
      { client_id: number; user_id: number }
    >({
      query: ({ client_id, user_id }) => {
        return {
          url: `/api/clients/${client_id}/users/${user_id}`,
          method: 'DELETE',
        };
      },
    }),
    getStates: build.query<IClientState[], any>({
      transformResponse: (response: { data: IClientState[] }) => response.data,
      query: () => ({
        url: '/api/clients/states',
        method: 'GET',
      }),
    }),
    getShowReportsINClient: build.query<
      IClientReports,
      { id: number; page: number; search: string }
    >({
      transformResponse: (response: { data: IClientReports }) => response.data,
      query: ({ id, page, search }) => ({
        url: `/api/clients/${id}/reports`,
        method: 'GET',
        params: {
          page,
          search,
        },
      }),
    }),
    getReportsDetailedINClient: build.query<IReports, any>({
      transformResponse: (response: { data: IReports }) => response.data,
      query: (id) => ({
        url: `/api/reports/${id}`,
        method: 'GET',
      }),
    }),
    getProductPricingClients: build.query<
      { total: number; data: IClientProduct[] },
      { id: number | undefined; search: string; page: number }
    >({
      transformResponse: (response: {
        data: { total: number; data: IClientProduct[] };
      }) => response.data,
      query: ({ id, search, page }) => ({
        url: `/api/clients/${id}/pricing`,
        method: 'GET',
        params: {
          search,
          page,
        },
      }),
    }),
    updateProductPricingClients: build.mutation<
      IClientProduct[],
      ClientCreateThirdStep
    >({
      transformResponse: (response: { data: IClientProduct[] }) =>
        response.data,
      query: (data) => ({
        url: `/api/clients/${data.id}/pricing`,
        method: 'POST',
        body: data,
      }),
    }),
    getNotesClients: build.query<any, number>({
      transformResponse: (response: { data: any }) => response.data,
      query: (id) => ({
        url: `api/clients/${id}/notes`,
        method: 'GET',
      }),
    }),
    postNotesClients: build.mutation<any, { id: number; note: string }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ id, note }) => ({
        url: `api/clients/${id}/notes`,
        method: 'POST',
        body: { note },
      }),
    }),
    getEquipments: build.query<
      IEquipmentResponse,
      { id: number; page: number; search: string }
    >({
      transformResponse: (response: { data: IEquipmentResponse }) =>
        response.data,
      query: ({ id, page, search }) => ({
        url: `/api/clients/${id}/equipments`,
        method: 'GET',
        params: {
          page,
          search,
        },
      }),
    }),
    deleteStepEquipment: build.mutation<
      IClientState[],
      { clientId: number; ids?: string }
    >({
      query: ({ ids, clientId }) => ({
        url: `/api/clients/${clientId}/equipments`,
        method: 'DELETE',
        body: {
          ids,
        },
      }),
    }),
    createEquipment: build.mutation<
      IClientState[],
      { clientId: number; data: IEquipment }
    >({
      query: ({ data, clientId }) => ({
        url: `/api/clients/${clientId}/equipments`,
        method: 'POST',
        body: data,
      }),
    }),
    updateEquipment: build.mutation<
      IClientState[],
      { clientId: number; data: any }
    >({
      query: ({ clientId, data }) => ({
        url: `/api/clients/${clientId}/equipments/${data.id}`,
        method: 'POST',
        body: data,
      }),
    }),
    createNote: build.mutation<IClientState[], { clientId: number; data: any }>(
      {
        query: ({ clientId, data }) => ({
          url: `/api/clients/${clientId}/notes`,
          method: 'POST',
          body: data,
        }),
      }
    ),
    deleteEquipment: build.mutation<IClientState[], any>({
      query: () => ({
        url: '/api/clients/states',
        method: 'DELETE',
      }),
    }),
    orderListOnCustomer: build.query<
      IOrderListOnCustomerResponse,
      {
        id: number;
        page: number;
        search: string;
        to_date: string;
        from_date: string;
        business_id: string;
        status_id: string;
      }
    >({
      transformResponse: (response: { data: IOrderListOnCustomerResponse }) =>
        response.data,
      query: ({
        id,
        page,
        search,
        to_date,
        from_date,
        business_id,
        status_id,
      }) => ({
        url: `/api/clients/${id}/orders`,
        method: 'GET',
        params: {
          page,
          search,
          to_date,
          from_date,
          business_id,
          status_id,
        },
      }),
    }),
    getProductsList: build.query<
      IClientProductResponse,
      ClientProductQueryParams
    >({
      transformResponse: (response: { data: IClientProductResponse }) =>
        response.data,
      query: ({ page = 1, search = '' }) => ({
        url: '/api/catalog/product-by-categories',
        method: 'GET',
        params: {
          page,
          search,
        },
      }),
    }),
    getCategories: build.query<IClientState[], any>({
      transformResponse: (response: { data: IClientState[] }) => response.data,
      query: () => ({
        url: '/api/clients/categories',
        method: 'GET',
      }),
    }),
    uploadImageFirstStep: build.mutation<ICreateClientStepOne, any>({
      transformResponse: (response: { data: ICreateClientStepOne }) =>
        response.data,
      query: (formData) => ({
        url: '/api/clients/step-one/upload-file',
        method: 'POST',
        body: formData,
      }),
    }),
    uploadImageSecondStep: build.mutation<IAgent, any>({
      transformResponse: (response: { data: IAgent }) => response.data,
      query: (formData) => ({
        url: '/api/clients/step-two/upload-file',
        method: 'POST',
        body: formData,
      }),
    }),
    createFirstStep: build.mutation<ICreateClientStepOneResponse, any>({
      transformResponse: (response: { data: ICreateClientStepOneResponse }) =>
        response.data,
      query: (data: ICreateClientStepOne) => ({
        url: '/api/clients/step-one',
        method: 'POST',
        body: data,
      }),
    }),
    createSecondStep: build.mutation<IAgent, any>({
      transformResponse: (response: { data: IAgent }) => response.data,
      query: (formData) => ({
        url: '/api/clients/step-two',
        method: 'POST',
        body: formData,
      }),
    }),
    createThirdStep: build.mutation<any, ClientCreateThirdStep>({
      query: (formData) => ({
        url: '/api/clients/step-three',
        method: 'POST',
        body: formData,
      }),
    }),
    createFouthStep: build.mutation<any, IClientStepFour>({
      query: (formData) => ({
        url: '/api/clients/step-four',
        method: 'POST',
        body: formData,
      }),
    }),
    uploadFourStepFile: build.mutation<
      string,
      { clientId: number | undefined; formData: any }
    >({
      query: ({ clientId, formData }) => ({
        url: `/api/clients/${clientId}/upload-file`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteUploadFileClient: build.mutation<
      string,
      { client_id: number; file_id: number }
    >({
      query: ({ client_id, file_id }) => ({
        url: `/api/clients/${client_id}/upload-file/${file_id}`,
        method: 'DELETE',
      }),
    }),
    getClientsBySearch: build.query<IClientBySearch[], string>({
      transformResponse: (response: { data: IClientBySearch[] }) =>
        response.data,
      query: (search) => ({
        url: `/api/clients/search`,
        method: 'GET',
        params: {
          search,
        },
      }),
    }),
    createSelfOrderClient: build.mutation<
      CreateSelfClientResponse,
      CreateSelfClient
    >({
      transformResponse: (response: { data: CreateSelfClientResponse }) =>
        response.data,
      query: (clientData) => ({
        url: `/api/clients/self-service`,
        method: 'POST',
        body: clientData,
      }),
    }),
    approveClient: build.mutation<any, string>({
      query: (clientId) => ({
        url: `/api/clients/${clientId}/approved`,
        method: 'POST',
      }),
    }),
    exportClientUsers: build.query<any, string>({
      query: (clientId) => ({
        url: `/api/clients/users/export`,
        method: 'GET',
      }),
    }),
    blockUnblockClient: build.mutation<
      any,
      { is_blocked: 0 | 1; client_id: string }
    >({
      query: ({ client_id, is_blocked }) => ({
        url: `/api/clients/${client_id}/blocked`,
        method: 'POST',
        params: {
          is_blocked,
        },
      }),
    }),
    getStatisticsInLine: build.query<IGetStatisticsInLine, string>({
      transformResponse: (response: { data: IGetStatisticsInLine }) =>
        response.data,
      query: (id) => ({
        url: `/api/clients/${id}/statistics-in-line`,
        method: 'GET',
      }),
    }),
    getPaymentList: build.query<
      ClientPaymentResponse,
      {
        date_from: string;
        date_to: string;
        search: string;
        clientId: string;
        is_pay: string;
        page: number;
      }
    >({
      transformResponse: (response: { data: ClientPaymentResponse }) =>
        response.data,
      query: ({ clientId, search, date_from, date_to, is_pay, page }) => ({
        url: `/api/clients/${clientId}/payments`,
        method: 'GET',
        params: {
          search,
          date_from,
          date_to,
          is_pay,
          page,
        },
      }),
    }),
    getPaymentStatistics: build.query<
      PaymentStatistic[],
      {
        date_from: string;
        date_to: string;
        search: string;
        clientId: string;
        page: number;
      }
    >({
      transformResponse: (response: { data: PaymentStatistic[] }) =>
        response.data,
      query: ({ clientId, search, date_from, date_to, page }) => ({
        url: `/api/clients/${clientId}/payment-statistics`,
        method: 'GET',
        params: {
          search,
          date_from,
          date_to,
          page,
        },
      }),
    }),
    pupularProductsGraph: build.query<
      IGraphTiful[],
      { id: string; from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: IGraphTiful[] }) => response.data,
      query: ({ id, from_date, to_date }) => ({
        url: `/api/clients/${id}/pupular-products`,
        method: 'GET',
        params: { from_date, to_date },
      }),
    }),
    salesGraph: build.query<any, { id: string; type: string; date: string }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ id, type, date }) => ({
        url: `/api/clients/${id}/sales`,
        method: 'GET',
        params: { type, date },
      }),
    }),
    uploadClientLogo: build.mutation<any, { id: string; formData: any }>({
      query: ({ id, formData }) => ({
        url: `/api/clients/${id}/logo`,
        method: 'POST',
        body: formData,
      }),
    }),
    compledRegister: build.mutation<any, string>({
      query: (id) => ({
        url: `/api/clients/${id}/compled-register`,
        method: 'POST',
      }),
    }),
    getRefundsReasons: build.query<any, { id: string | number }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ id }) => ({
        url: `/api/clients/${id}/refunds/reasons`,
        method: 'GET',
      }),
    }),
    getRefunds: build.query<
      IClientRefundsReasonsResponse,
      { id: string; from_date: string; to_date: string }
    >({
      transformResponse: (response: { data: IClientRefundsReasonsResponse }) =>
        response.data,
      query: ({ id, from_date, to_date }) => ({
        url: `/api/clients/${id}/refunds`,
        method: 'GET',
        params: { to_date, from_date },
      }),
    }),
    postRefundsReasons: build.mutation<
      any,
      { id: string; formData: IClientRefundsReasons }
    >({
      query: ({ id, formData }) => ({
        url: `/api/clients/${id}/refunds`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteRefundsReasons: build.mutation<
      any,
      { id: string; refund_id: string | number }
    >({
      query: ({ id, refund_id }) => ({
        url: `/api/clients/${id}/refunds/${refund_id}`,
        method: 'DELETE',
      }),
    }),
    postRefunds: build.mutation<
      any,
      {
        id: string;
        formData: IClientRefundsReasons;
        refund_id: string | number;
      }
    >({
      query: ({ id, formData, refund_id }) => ({
        url: `/api/clients/${id}/refunds/${refund_id}`,
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});
