import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../../../hooks/redux';
import { routesByRole } from '../../../../routes/routes';
import { reportsApi } from '../../../../services/reports';
import { userApi } from '../../../../services/user';

const useLinks = () => {
  const userRole = useAppSelector((state) => state.user.info.role);
  const location = useLocation();
  const { data, refetch } = userApi.useGetMenuCountersQuery('');
  const [updateNewAgentMessageCounter] =
    reportsApi.useUpdateNewAgentMessageCounterMutation();

  useEffect(() => {
    if (location.pathname.includes('orders')) {
      refetch();
    }
    if (location.pathname.includes('tasks')) {
      updateNewAgentMessageCounter('').then(() => refetch());
    }
  }, [location.pathname, refetch, updateNewAgentMessageCounter]);

  const links = React.useMemo(() => {
    if (userRole !== 'none') {
      return routesByRole[userRole]
        .filter((route) => route.pageTitle && route.renderIcon)
        .map((route, index) => {
          const count = data && route.counterKey ? data[route.counterKey] : 0;
          const hasCounter = count > 0;
          return {
            id: index,
            title: route.pageTitle,
            path: route.route,
            pathKey: route.pathKey || '',
            icon:
              route.renderIcon &&
              route.renderIcon(location.pathname, hasCounter),
            count,
          };
        });
    } else {
      return [];
    }
  }, [location.pathname, userRole, data]);

  return links;
};

export default useLinks;
