import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import { createStandaloneToast } from '@chakra-ui/react';
import { base64toBlob, getErrorRetail } from '../helpers/common';
import { logout } from '../redux/userSlice';
const toast = createStandaloneToast();

function s2ab(s: any) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const requestUrl =
        action.meta.baseQueryMeta.request.url.split('bsx-group.com')[1];
      if (
        action?.payload?.status === 401 ||
        action?.payload?.originalStatus === 401
      ) {
        api.dispatch(logout());
      } else {
        toast({
          title: `${'טעות'}`,
          description:
            `route: ${requestUrl}` + ',\n' + getErrorRetail(action.payload),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }

    return next(action);
  };
