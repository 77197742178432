import { SelectedFile } from './../Components/Pages/Main/Clients/NewClientStepFour/useNewClientStepFour';
import { createSelector } from '@reduxjs/toolkit';
import {
  IClientProduct,
  IClientShowUsers,
  IClientStepFour,
  IClientProductResponse,
  StepFourField,
  StepOneField,
  StepTwoField,
  BankDetailField,
  BookkeepingField,
  Step,
} from './../models/IClient/IClient';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { clientsApi } from '../services/clients';
import {
  ICreateClientStepOne,
  ICreateClientStepTwo,
  IFirstStep,
  ISecondStep,
} from '../models/IClient/IClient';
import { v4 } from 'uuid';
import { calculateProductsPrice, setProperty } from '../helpers/common';
import moment from 'moment';

export const convertDateFormat = (date: string | undefined) => {
  if (!date) return '';
  if (date.includes('/')) return date;
  return date.split('-').reverse().join('/');
};

interface StepThree {
  isDone: boolean;
  data: IClientProduct[];
}
interface StepFour {
  isDone: boolean;
  data: IClientStepFour;
}

interface StepFive {
  isDone: boolean;
  data: { note: string };
}
interface IClientState {
  isLoading: boolean;
  error: string;
  step: Step;
  clientSettings: IFirstStep;
  firstStep: IFirstStep;
  secondStep: ISecondStep;
  stepThree: StepThree;
  stepFour: StepFour;
  clientUserData: any;
  stepFive: StepFive;
}

const initialState: IClientState = {
  clientUserData: {},
  isLoading: false,
  error: '',
  clientSettings: {
    isDone: false,
    data: {
      users: [
        { full_name: '', user_phone: '', is_create_order: false, id: v4() },
      ],
    },
  },
  firstStep: {
    isDone: false,
    data: {
      users: [
        { full_name: '', user_phone: '', is_create_order: false, id: v4() },
      ],
    },
  },
  secondStep: {
    isDone: false,
    data: {
      bank_detail: {
        branch: '',
        invoice: '',
        is_email_invoice: false,
        is_personal_invoice: false,
        business_id: NaN,
        bank_id: '',
        created_at: '',
        updated_at: '',
      },
      bookkeeping: {},
      image: '',
    },
  },
  stepThree: {
    isDone: false,
    data: [],
  },
  stepFive: {
    isDone: false,
    data: {
      note: '',
    },
  },
  stepFour: {
    isDone: false,
    data: {
      distributions: [
        {
          id: v4(),
          line_id: '',
          day: '1',
          dayTitle: 'א׳',
          selected: false,
        },
        {
          id: v4(),
          line_id: '',
          day: '2',
          dayTitle: 'ב׳',
          selected: false,
        },
        {
          id: v4(),
          line_id: '',
          day: '3',
          dayTitle: 'ג׳',
          selected: false,
        },
        {
          id: v4(),
          line_id: '',
          day: '4',
          dayTitle: 'ד׳',
          selected: false,
        },
        {
          id: v4(),
          line_id: '',
          day: '5',
          dayTitle: 'ה׳',
          selected: false,
        },
        {
          id: v4(),
          line_id: '',
          day: '6',
          dayTitle: 'ו׳',
          selected: false,
        },
        {
          id: v4(),
          line_id: '',
          day: '7',
          dayTitle: 'ש׳',
          selected: false,
        },
      ],
      selectedFiles: [],
      obligo: '',
      is_user_app: false,
      payment_type_id: '',
      payment_method: '',
      sales_goal: '',
      terms_payment: '',
      bank_id: '',
      payment_option_id: '',
    },
  },
  step: 1,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setUsersField: (
      state,
      action: PayloadAction<{ id: string; fieldName: string; value: string }>
    ) => {
      const { id, fieldName, value } = action.payload;
      state.firstStep.data.users =
        state.firstStep.data.users?.map((user) =>
          user.id === id ? { ...user, [fieldName]: value } : user
        ) || [];
    },
    setAddNewUsersFields: (state, action: PayloadAction<string>) => {
      state.firstStep.data.users = [
        ...(state.firstStep.data.users || []),
        {
          id: action.payload,
          full_name: '',
          user_phone: '',
          is_create_order: false,
        },
      ];
    },
    deleteUsersFields: (state, action: PayloadAction<string>) => {
      state.firstStep.data.users = state.firstStep.data.users?.filter(
        (item) => item.id !== action.payload
      );
    },
    setUsersFieldSettings: (
      state,
      action: PayloadAction<{ id: string; fieldName: string; value: string }>
    ) => {
      const { id, fieldName, value } = action.payload;
      state.clientSettings.data.users =
        state.clientSettings.data.users?.map((user) =>
          user.id === id ? { ...user, [fieldName]: value } : user
        ) || [];
    },
    setAddNewUsersFieldsSettings: (state, action: PayloadAction<string>) => {
      state.clientSettings.data.users = [
        ...(state.clientSettings.data.users || []),
        {
          id: action.payload,
          full_name: '',
          user_phone: '',
          is_create_order: false,
        },
      ];
    },
    deleteUsersFieldsSettings: (state, action: PayloadAction<string>) => {
      state.clientSettings.data.users = state.clientSettings.data.users?.filter(
        (item) => item.id !== action.payload
      );
    },
    setClientUserData: (state, action: PayloadAction<any>) => {
      const { expiry_date } = action.payload;
      state.clientUserData = {
        ...action.payload,
        expiry_date: expiry_date
          ? moment(convertDateFormat(expiry_date), 'DD/MM/YYYY').toDate()
          : '',
      };
    },
    resetClientUserData: (state) => {
      state.clientUserData = {};
    },
    setNewClientStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setStepOneDone: (state) => {
      state.firstStep.isDone = true;
    },
    setStepTwoDone: (state) => {
      state.secondStep.isDone = true;
    },
    setNewClientStepOneData: (
      state,
      action: PayloadAction<{ field: StepOneField; value: string }>
    ) => {
      const { field, value } = action.payload;
      setProperty(state.firstStep.data, field, value);
    },
    setNewClientStepTwoData: (
      state,
      action: PayloadAction<{ field: StepTwoField; value: string }>
    ) => {
      const { field, value } = action.payload;
      setProperty(state.secondStep.data, field, value);
    },
    setBankDetailsData: (
      state,
      action: PayloadAction<{ field: BankDetailField; value: string }>
    ) => {
      const { field, value } = action.payload;
      setProperty(state.secondStep.data.bank_detail, field, value);
    },
    setBookkeepingData: (
      state,
      action: PayloadAction<{ field: BookkeepingField; value: string }>
    ) => {
      const { field, value } = action.payload;
      setProperty(state.secondStep.data.bookkeeping, field, value);
    },
    setCategoryDiscount: (
      state,
      action: PayloadAction<{ id: number; discount: number }>
    ) => {
      const { id, discount } = action.payload;
      state.stepThree.data = state.stepThree.data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            products: item.products.map((product) => ({
              ...product,
              discount,
            })),
            discount,
          };
        } else {
          return item;
        }
      });
    },
    setProductDiscount: (
      state,
      action: PayloadAction<{ id: number; discount: number }>
    ) => {
      const { id, discount } = action.payload;
      state.stepThree.data = state.stepThree.data.map((item) => ({
        ...item,
        products: item.products.map((product) =>
          product.id === id ? { ...product, discount } : product
        ),
      }));
    },
    setThirdStepDone: (state) => {
      state.stepThree.isDone = true;
    },
    setFourthStepDone: (state) => {
      state.stepFour.isDone = true;
    },
    selectDistribution: (state, action: PayloadAction<{ id: string }>) => {
      state.stepFour.data.distributions = state.stepFour.data.distributions.map(
        (distribution) =>
          distribution.id === action.payload.id
            ? {
                ...distribution,
                selected: !distribution.selected,
              }
            : distribution
      );
    },
    selectStepFourFiles: (state, action: PayloadAction<{ file: any }>) => {
      const selectedFile: SelectedFile = {
        id: v4(),
        file: action.payload.file,
      };
      state.stepFour.data.selectedFiles = [
        ...(state.stepFour.data.selectedFiles || []),
        selectedFile,
      ];
    },
    deleteStepFourFiles: (state, action: PayloadAction<{ id: string }>) => {
      state.stepFour.data.selectedFiles = (
        state.stepFour.data.selectedFiles || []
      ).filter((file) => file.id != action.payload.id);
    },
    selectDistributionLineID: (
      state,
      action: PayloadAction<{ id: string; line_id: number }>
    ) => {
      state.stepFour.data.distributions = state.stepFour.data.distributions.map(
        (distribution) =>
          distribution.id === action.payload.id
            ? {
                ...distribution,
                line_id: String(action.payload.line_id),
              }
            : distribution
      );
    },
    setStepFourFields: (
      state,
      action: PayloadAction<{
        fieldName: StepFourField;
        value: string;
        id?: string;
      }>
    ) => {
      const { fieldName, value, id } = action.payload;
      if (id) {
        state.stepFour.data.distributions =
          state.stepFour.data.distributions.map((distribution) =>
            distribution.id === id
              ? { ...distribution, [fieldName]: value }
              : distribution
          );
      } else {
        setProperty(state.stepFour.data, fieldName, value);
      }
    },
    setPreviousStep: (state) => {
      state.step = state.step - 1;
    },
    setInitialNewClientState: (state) => {
      state = initialState;
    },
    setStepFiveNote: (state, action: PayloadAction<string>) => {
      state.stepFive.data.note = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Clients ID "new clients" first step
    builder
      .addMatcher(
        clientsApi.endpoints.createFirstStep.matchFulfilled,
        (state, action) => {
          state.firstStep.data.client_id = action.payload.id;
        }
      )
      .addMatcher(
        clientsApi.endpoints.getProductsList.matchFulfilled,
        (state, action) => {
          state.stepThree.data = action.payload.data;
        }
      )
      .addMatcher(
        clientsApi.endpoints.getProductPricingClients.matchFulfilled,
        (state, action) => {
          state.stepThree.data = [];
          state.stepThree.data = action.payload.data;
        }
      )
      .addMatcher(
        clientsApi.endpoints.getClientSetting.matchFulfilled,
        (state, action) => {
          state.clientSettings.data.users = action.payload.clients?.map(
            (item) => ({
              full_name: item.full_name,
              phone: item.phone,
              is_create_order: Boolean(item.is_create_order),
              id: String(item.id),
            })
          );
        }
      )
      .addMatcher(
        clientsApi.endpoints.getClientSetting.matchFulfilled,
        (state, action) => {
          state.stepFour.data.distributions =
            state.stepFour.data.distributions.map((item) => {
              const payloadItem = action.payload.distributions?.find(
                (el) => String(el.day) === item.day
              );
              return payloadItem
                ? {
                    ...item,
                    selected: true,
                    line_id: String(payloadItem.line_id),
                  }
                : item;
            });
        }
      );
  },
});

export const selectStepFourData = (state: RootState) =>
  state.client.stepFour.data;
export const selectProducts = (state: RootState) => state.client.stepThree.data;

export const selectClientProducts = createSelector(
  selectProducts,
  (productsByCategories) =>
    productsByCategories.map((category) => {
      return {
        ...category,
        products: category.products.map((product) => ({
          ...product,
          client_discount: !product.client_discount
            ? category.discount
            : product.client_discount,
          totalPrice: calculateProductsPrice(product, product.discount),
        })),
      };
    })
);

export const selectClientUserData = (state: RootState) =>
  state.client.clientUserData;
export const selectStepsStatus = (state: RootState) => {
  const client = state.client;
  return [
    {
      stepCount: 1,
      isDone: client.firstStep.isDone,
      text: 'פרטי לקוח',
    },
    {
      stepCount: 2,
      isDone: client.secondStep.isDone,
      text: 'ה.חשבונות',
    },
    {
      stepCount: 3,
      isDone: client.stepThree.isDone,
      text: 'מחירון',
    },
    {
      stepCount: 4,
      isDone: client.stepFour.isDone,
      text: 'אשראי',
    },
    {
      stepCount: 5,
      isDone: client.stepFive.isDone,
      text: 'ציוד והערות',
    },
  ];
};
export const selectStepFiveNote = (state: RootState) =>
  state.client.stepFive.data.note;
export const selectUsersContacts = (state: RootState) =>
  state.client.firstStep.data.users;
export const selectUsersContactsSettings = (state: RootState) =>
  state.client.clientSettings.data.users;
export const selectNewClientStep = (state: RootState) => state.client.step;
export const selectClientIDFirstStep = (state: RootState) =>
  state.client.firstStep.data.client_id;
export const selectNewClientStepOneData = (state: RootState) =>
  state.client.firstStep.data;
export const selectNewClientStepTwoData = (state: RootState) =>
  state.client.secondStep.data;

export const {
  setNewClientStep,
  setNewClientStepOneData,
  setProductDiscount,
  setCategoryDiscount,
  setThirdStepDone,
  setStepFourFields,
  setFourthStepDone,
  setNewClientStepTwoData,
  setAddNewUsersFields,
  setUsersField,
  deleteUsersFields,
  setAddNewUsersFieldsSettings,
  setUsersFieldSettings,
  deleteUsersFieldsSettings,
  setClientUserData,
  selectDistribution,
  selectDistributionLineID,
  selectStepFourFiles,
  deleteStepFourFiles,
  setBankDetailsData,
  setBookkeepingData,
  setStepOneDone,
  setStepTwoDone,
  setPreviousStep,
  setInitialNewClientState,
  setStepFiveNote,
  resetClientUserData,
} = clientSlice.actions;

export default clientSlice.reducer;
