import {
  extendTheme,
  FlexProps,
  FormLabelProps,
  TextProps,
} from '@chakra-ui/react';
// import { createBreakpoints } from '@chakra-ui/theme-tools';
import { Button } from './components/Button';
import { Input } from './components/Input';

export const mainWrapTable: FlexProps = {
  w: 'full',
};

export const responsiveTableWrap: FlexProps = {
  boxShadow: '0 4px 20px 0 rgba(52, 27, 24, 0.15)',
  w: 'full',
  overflowX: 'auto',
  sx: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '5px',
      borderRadius: '15px',
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `brand.grey`,
      borderRadius: '15px',
    },
  },
  borderRadius: '20px',
};

export const smTextBold: TextProps = {
  fontSize: '14px',
  color: 'brand.black',
  fontWeight: 'bold',
  lineHeight: '19px',
}; // 14px bold

export const smText: TextProps = {
  fontSize: '14px',
  color: 'brand.black',
  fontWeight: 'normal',
  lineHeight: '19px',
}; // 14px normal

export const xSmallTextStyle: TextProps = {
  fontSize: '12px',
  color: 'brand.black',
  fontWeight: 'light',
  lineHeight: '5px',
  letterSpacing: '-0.17px',
}; //12px light

export const xsTextBoldStyle: TextProps = {
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: '20px',
  color: 'brand.white',
  letterSpacing: '-0.24px',
}; // 12px bold

export const xsTextStyle: TextProps = {
  fontSize: '12px',
  fontWeight: 'normal',
  lineHeight: '20px',
  color: 'brand.white',
  letterSpacing: '-0.24px',
}; // 12px normal

export const xsTextStyleLabel: FormLabelProps = {
  fontSize: 'xs',
  fontWeight: 'normal',
  lineHeight: '20px',
  color: 'brand.white',
  letterSpacing: '-0.24px',
  mb: '0px',
}; // 12px normal

export const fourXLBoldText: TextProps = {
  fontSize: '36px',
  fontWeight: 'bold',
  lineHeight: '49px',
  color: 'brand.black',
}; // 36px bold

export const TwoXLText: TextProps = {
  fontSize: '24px',
  fontWeight: 'normal',
  lineHeight: '18px',
  color: 'brand.black',
}; // 24px normal

export const TwoXLBoldText: TextProps = {
  fontSize: '24px',
  fontWeight: 'bold',
  lineHeight: '32px',
  color: 'brand.black',
}; // 24px bold

export const lgBoldText: TextProps = {
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '24px',
  color: 'brand.black',
}; // 18px bold

export const lgText: TextProps = {
  fontSize: '18px',
  fontWeight: 'normal',
  lineHeight: '24px',
  color: 'brand.black',
}; // 18px normal

export const mdText: TextProps = {
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '22px',
  color: 'brand.black',
}; // 16px normal

export const mdBoldText: TextProps = {
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '22px',
  color: 'brand.black',
}; // 16px bold

// const breakpoints = createBreakpoints({
//   sm: '320px',
//   md: '768px',
//   lg: '960px',
//   xl: '1200px',
//   'xlx': '1536px',
// });

export const theme = extendTheme({
  // breakpoints,
  shadows: {
    xlx: '0 4px 20px 0 rgba(52, 27, 24, 0.15)',
  },
  components: {
    Button,
    Input,
    variants: {
      first: {
        colore: 'red',
      },
    },
  },

  styles: {
    global: {
      'div, span, button, p, input, th, td': {
        fontFamily: 'Open Sans Hebrew, sarif',
        letterSpacing: '-0.17px',
        lineHeight: '18px',
      },
      input: {
        borderRadius: '15px',
        h: '50px',
        bg: '#393945',
        borderColor: 'white',
        border: '1px',
      },
    },
  },
  variant: {
    first: {
      colore: 'red',
    },
  },
  colors: {
    line: {
      blue: '#4990D2',
      sky: '#A2CBF0',
      yellow: '#FFE6AD',
      pink: '#D27266',
      lightBordo: '#891308',
      bordo: '#6E1105',
    },
    brand: {
      500: '#891308',
      bordo: '#891308',
      pink: '#D27266',
      red: '#EB5757',
      green: '#27AE60',
      bordo2: '#6E1105',
      yellow: '#FFE6AD',
      black: '#161621',
      white: '#FFFFFF',
      darkBlue: '#285C8C',
      blue: '#4281BD',
      grey: 'rgba(57, 57, 69, 0.6)',
      border: '#797983',
      border1: '#BDBDBD',
      border2: '#E5E5E5',
      border3: '#C9CAD1',
      border4: '#DDE2E5',
      bgWhite: 'rgba(255, 255, 255, 0.9)',
      bgGrey: '#F2F2F2',
      sky: '#4990D2',
      sky2: '#0F73AB',
      text: '#6E798C',
      text2: '#495057',
      text3: '#333533',
      lightGrey: '#E8E5E5',
      lightBlue: 'rgba(73, 144, 210, 0.1)',
      greyBtn: '#F2F2F2',
      colorBtn: '#BDBDBD',
    },
  },
});
