import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import AppFallback from "../Components/Common/AppFallback/AppFallback";
import { useAppSelector } from "../hooks/redux";
import { selectUser } from "../redux/userSlice";
import { userApi } from "../services/user";
import { IRoute } from "../types/types";
import { routesByRole, defaultRoutesByRole } from "./routes";

const RoutesByRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = useAppSelector((state) => state.user.info.role);

  React.useEffect(() => {
    if (userRole !== "none") {
      if (!location.pathname.includes(userRole)) {
        navigate(defaultRoutesByRole[userRole]);
      }
      if (location.pathname.includes("login")) {
        navigate(defaultRoutesByRole[userRole]);
      }
    } else {
      if (location.pathname.includes('login')) return
      navigate("/login");
    }
  }, [userRole]);

  return (
    <React.Suspense fallback={<AppFallback />}>
      <Routes>
        {routesByRole[userRole].map((route: IRoute) => (
          <Route key={route.route} path={route.route} element={route.element} />
        ))}
      </Routes>
    </React.Suspense>
  );
};

export default RoutesByRole;
