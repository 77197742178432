import {
  CreateClientNotification,
  CreateNotification,
  IGetQuestion,
} from './../models/INotification/INotification';
import { RootState } from './../redux/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { INotificationResponse } from '../models/INotification/INotification';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  tagTypes: ['Notification'],
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://balkan-api.dev.bsx-group.com',
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).user.info.api_token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getNotifications: build.query<
      INotificationResponse,
      { search: string; page: number }
    >({
      transformResponse: (response: { data: INotificationResponse }) =>
        response.data,
      query: ({ search, page }) => ({
        url: '/api/notifications',
        method: 'GET',
        params: {
          page,
          search,
        },
      }),
    }),
    getMainAgentsNotifications: build.query<
      INotificationResponse,
      {
        search?: string;
        page?: number;
        from_date?: string;
        to_date?: string;
        agent_ids?: number[] | undefined;
        clients?: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: INotificationResponse }) =>
        response.data,
      query: ({ search, page, to_date, from_date, agent_ids, clients }) => ({
        url: '/api/agents/notifications',
        method: 'GET',
        params: {
          page,
          search,
          to_date,
          from_date,
          agent_ids: JSON.stringify(agent_ids),
          clients: JSON.stringify(clients),
        },
      }),
    }),
    getMainClientsNotifications: build.query<
      INotificationResponse,
      {
        search?: string;
        page?: number;
        from_date?: string;
        to_date?: string;
        agent_ids?: number[] | undefined;
        clients?: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: INotificationResponse }) =>
        response.data,
      query: ({ search, page, to_date, from_date, agent_ids, clients }) => ({
        url: '/api/clients/notifications',
        method: 'GET',
        params: {
          page,
          search,
          to_date,
          from_date,
          agent_ids: JSON.stringify(agent_ids),
          clients: JSON.stringify(clients),
        },
      }),
    }),
    getMainNotifications: build.query<
      INotificationResponse,
      {
        search?: string;
        page?: number;
        from_date?: string;
        to_date?: string;
        agent_ids?: number[] | undefined;
        clients?: number[] | undefined;
      }
    >({
      transformResponse: (response: { data: INotificationResponse }) =>
        response.data,
      query: ({ search, page, to_date, from_date, agent_ids, clients }) => ({
        url: '/api/agents/notifications',
        method: 'GET',
        params: {
          page,
          search,
          to_date,
          from_date,
          agent_ids: JSON.stringify(agent_ids),
          clients: JSON.stringify(clients),
        },
      }),
    }),
    createNotification: build.mutation<
      INotificationResponse,
      CreateNotification
    >({
      query: (notification) => ({
        url: `/api/agents/notifications`,
        method: 'POST',
        body: notification,
      }),
    }),
    deleteNotification: build.mutation<any, string>({
      query: (id) => ({
        url: `/api/notifications/${id}`,
        method: 'DELETE',
      }),
    }),
    readAllNotifications: build.mutation<any, string>({
      query: () => ({
        url: `/api/notifications/read`,
        method: 'POST',
      }),
    }),
    createClientNotification: build.mutation<
      INotificationResponse,
      CreateClientNotification
    >({
      query: (notification) => ({
        url: `/api/clients/notifications`,
        method: 'POST',
        body: notification,
      }),
    }),
    getAgentNotifications: build.query<
      INotificationResponse,
      {
        search: string;
        page: number;
        id: string;
        from_date: string;
        to_date: string;
        type_message: string;
      }
    >({
      transformResponse: (response: { data: INotificationResponse }) =>
        response.data,
      query: ({ search, page, id, from_date, to_date, type_message }) => ({
        url: `/api/agents/${id}/notifications`,
        method: 'GET',
        params: {
          search,
          page,
          from_date,
          to_date,
          type_message,
        },
      }),
      providesTags: () => ['Notification'],
    }),
    createAgentNotification: build.mutation<
      INotificationResponse,
      { data: Omit<CreateNotification, 'title'>; agent_id: number }
    >({
      transformResponse: (response: { data: INotificationResponse }) =>
        response.data,
      query: ({ data, agent_id }) => ({
        url: `/api/agents/${agent_id}/notifications`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Notification'],
    }),
    getQuestion: build.query<IGetQuestion, { id: number }>({
      transformResponse: (response: { data: IGetQuestion }) => response.data,
      query: ({ id }) => ({
        url: `/api/question/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['Notification'],
    }),
    postQuestion: build.mutation<any, { id: number; variable_id: number }>({
      transformResponse: (response: { data: any }) => response.data,
      query: ({ id, variable_id }) => ({
        url: `/api/question/${id}`,
        method: 'POST',
        body: { variable_id },
      }),
      invalidatesTags: ['Notification'],
    }),
  }),
});
