import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Input } from '../../../Common/Input/Input';
import eye from '../../../../Images/SVG/eye.svg';
import IButton from '../../../Common/Button/Button';
import {
  Box,
  BoxProps,
  Center,
  FormControl,
  FormLabel,
  FormLabelProps,
  Image,
  InputGroup,
  InputLeftElement,
  InputProps,
  Text,
} from '@chakra-ui/react';
import { userApi } from '../../../../services/user';
import { useNavigate } from 'react-router';
import { emailPattern } from '../../../../constants/constants';

const LoginFirstForm = () => {
  const [login, { isLoading, error }] = userApi.useLoginMutation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const formRef = useRef<any>(null);
  const handleClickShowPass = () => setShowPass(!showPass);

  const loginUser = () => {
    login({
      email,
      password,
    });
  };

  const isEmailValid = useMemo(() => emailPattern.test(email), [email]);

  const isPasswordValid = useMemo(() => password.length >= 8, [password]);

  const getButtonTitle = useCallback(() => {
    if (!isEmailValid) {
      return 'האימייל אינו חוקי';
    }
    if (!isPasswordValid) {
      return 'הסיסמה אינה חוקית';
    }
  }, [isEmailValid, isPasswordValid]);

  return (
    <form onSubmit={loginUser}>
      <Center flexDir="column" ref={formRef}>
        <Box m="25px 0 20px" h="20px">
          {error && (
            <Text color="brand.red" fontSize="18px">
              סיסמה או דוא"ל שגויים
            </Text>
          )}
        </Box>
        <FormControl maxW="345px" isInvalid={!isEmailValid}>
          <FormLabel {...labelStyle}>כתובת מייל</FormLabel>
          <Input
            {...inputStyle}
            className="login"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            id="email"
            type="email"
            border={
              error || !isEmailValid ? '1px solid red' : '1px solid brand.white'
            }
          />
        </FormControl>
        <FormControl maxW="345px" mt="15px" isInvalid={!isPasswordValid}>
          <FormLabel {...labelStyle}>סיסמה</FormLabel>
          <InputGroup>
            <Input
              {...inputStyle}
              className="login"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              id="password"
              type={showPass ? 'text' : 'password'}
              border={
                error || !isPasswordValid
                  ? '1px solid red'
                  : '1px solid brand.white'
              }
            />
            <InputLeftElement h="full" w="50px" onClick={handleClickShowPass}>
              <Center
                pos="relative"
                _after={
                  showPass
                    ? {
                        content: '""',
                        pos: 'absolute',
                        bg: 'brand.white',
                        w: 'full',
                        h: '1px',
                        transform: 'rotate(-40deg)',
                      }
                    : {}
                }
              >
                <Image src={eye} alt="" />
              </Center>
            </InputLeftElement>
          </InputGroup>
        </FormControl>
        <Box onClick={() => navigate('/login/reset-email')} {...forgotPass}>
          שכחת סיסמה?
        </Box>
        <IButton
          isDisabled={!isEmailValid || !isPasswordValid}
          title={getButtonTitle()}
          type="submit"
          text=" כניסה"
          // isError={isError}
          isError={!isEmailValid || !isPasswordValid}
          isLoading={isLoading}
          onClick={loginUser}
        />
      </Center>
    </form>
  );
};

const forgotPass: BoxProps = {
  color: 'brand.blue',
  w: 'full',
  maxW: '345px',
  m: ' 10px 50px 25px 0',
  _hover: { cursor: 'pointer' },
};

const labelStyle: FormLabelProps = {
  color: 'brand.yellow',
  fontSize: '16px',
  htmlFor: 'email',
  m: '0 20px 15px 0',
};

const inputStyle: InputProps = {
  variant: 'first',
  fontSize: '18px',
  color: 'white !important',
};

export default LoginFirstForm;
